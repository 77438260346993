import ShadowTrackerDetailView from '@/views/automations/shadow-tracker_detail.view';
import PlayerView from '@/views/player.view';
import ScoutingProfileView from '@/views/scouting/scouting-profile.view';

import ScoutingView from '@/views/scouting/scouting.view';
import React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';

function ScoutingRoutes() {
  return (
    <Routes>
      <Route path="" element={<ScoutingView />} key="scouted-players" />
      <Route
        path="profiles/:scouting_profile_id/*"
        element={<ScoutingProfileView />}
      />

      <Route
        path="profiles/:scouting_profile_id/players/:player_id"
        element={<PlayerView />}
      />

      <Route path="players/:player_id" element={<PlayerView />} />
      <Route
        path="profiles/:scouting_profile_id/players/:player_id/shadow-trackers/:id"
        element={<ShadowTrackerDetailView />}
      />
      <Route path="shadow-trackers/:id" element={<ShadowTrackerDetailView />} />

      {/* Redirect */}
      <Route path="*" element={<Navigate to="" />} />
    </Routes>
  );
}

export default ScoutingRoutes;
