import React, {useEffect, useState} from 'react';

import Stack from '@mui/material/Stack';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Fade from '@mui/material/Fade';
import CircularProgress from '@mui/material/CircularProgress';

import {
  FIEventAutomationPostData,
  FIFormattedAutomation,
  FIFormattedAutomationPlayerScore,
  FIFormattedAutomationPlayerScores,
  FIMatch,
  FITag,
  TEventAutomationType,
  shouldInvertScore,
} from '@my-game-plan/types';
import {formattedAutomationToPostData} from '@/helpers/event-automations.helper';
import InlineFiltersSentence from '@/components/automations/sentence/builder-sentence.view';
import {useTranslation} from 'react-i18next';
import {useTheme} from '@mui/material';
import {useTeams} from '@/context/team.context';
import {hashtagify} from '@/helpers/translation.helper';
import {NavLink, useLocation} from 'react-router-dom';
import {fetchEventsForAutomation} from '@/controllers/event-automations.controller';
import {useVideo} from '@/context/video/video.context';
import {useSnackbar} from 'notistack';

import {formatOpponentAutomationScores} from '@/helpers/automation/automation-detail-history.helper';
import {
  isNullOrUndefined,
  roundAndFormatNumber,
} from '@/helpers/general/general.helper';
import {getColorForScore} from '@/helpers/benchmark.helper';
import {generateURLForAutomation} from '@/helpers/automation/automation.helper';

import {useOverviewScreen} from '@/context/overview-screen-context';

export interface IAutomationTableCell {
  label: string;
  key:
    | 'title'
    | 'score'
    | 'tags'
    | 'average'
    | 'benchmark_average'
    | 'last_match'
    | 'buttons';
  align?: 'left' | 'right' | 'center';
  hideOnSmallScreen?: boolean;
}

interface IAutomationsTableRowProps {
  automation: FIFormattedAutomation;
  automationType: TEventAutomationType;
  cells: IAutomationTableCell[];
  benchmarkLabel?: string;
  matches: FIMatch[];
}

const TAGS_TO_SHOW = 2;

function AutomationsTableRow(
  props: IAutomationsTableRowProps,
): JSX.Element | null {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const {breakpoints} = useTheme();
  const _teamsContext = useTeams();
  const _videoPlayerContext = useVideo();
  const _snackbarContext = useSnackbar();
  const _location = useLocation();
  const _overviewScreenContext = useOverviewScreen();

  const [_automationData, _setAutomationData] =
    useState<Partial<FIEventAutomationPostData>>();
  const [_scores, _setScores] =
    useState<FIFormattedAutomationPlayerScores | null>(null);
  const [_benchmarkedScore, _setBenchmarkedScore] = useState<
    number | undefined
  >(undefined);
  const [_tags, _setTags] = useState<FITag[]>([]);
  const [_isLoadingVideos, _setIsLoadingVideos] = useState<boolean>(false);

  const [_automationURL, _setAutomationURL] = useState<string>('');

  useEffect(() => {
    const _postData = formattedAutomationToPostData(props.automation);
    const _defaultBenchmarkOptions = props.automation.benchmark_filters;
    if (props.automation.players?.length && props.automation.benchmark_scores) {
      const _shouldInvertScores = shouldInvertScore(props.automation);

      const _lastMatch = props.automation.players[0].history.reduce(
        (latest, current) => {
          return new Date(current.match.date) > new Date(latest.match.date)
            ? current
            : latest;
        },
        props.automation.players[0].history[0],
      );

      const _formattedScores = formatOpponentAutomationScores(
        props.automation.action,
        props.automation.benchmark_scores,
        _lastMatch?.score,
        props.automation.calculation,
        props.automation.rule_condition?.action,
        false,
        _defaultBenchmarkOptions?.calculation === 'per_90',
        _shouldInvertScores,
        props.automation.metric,
      );

      _setBenchmarkedScore(
        props.automation.benchmark_scores?.benchmarked_score,
      );

      _setScores(_formattedScores);
    }
    _setAutomationData(_postData);
  }, [props.automation]);

  useEffect(() => {
    const _foundTags: FITag[] = [];

    if (_teamsContext.preferences) {
      props.automation.tags?.forEach((tagId) => {
        const _foundTag = _teamsContext.preferences?.tags?.find(
          (tag) => tag._id === tagId,
        );
        if (_foundTag) {
          _foundTags.push({
            ..._foundTag,
            label: `#${hashtagify(_foundTag.label, false)}`,
          });
        }
      });
    }

    _setTags(_foundTags);
  }, [props.automation, _teamsContext.preferences]);

  // Define URL for automation deep dive
  useEffect(() => {
    const _URL = generateURLForAutomation(
      props.automationType,
      props.automation.statistic_id,
      props.automation._id,
      undefined,
      Boolean(props.automation.observing_players?.players?.length),
      undefined,
      _overviewScreenContext.selectedOpponent ?? undefined,
      _location.search,
    );

    _setAutomationURL(_URL);
  }, [
    props.automation,
    _location,
    props.automationType,
    _overviewScreenContext.selectedOpponent,
  ]);

  /*
   * Handlers
   */
  async function _onWatchVideosClick() {
    try {
      _setIsLoadingVideos(true);

      const _events = await fetchEventsForAutomation(props.automationType, {
        id: props.automation._id,
      });
      const _matchIdsInEvents = _events.map((event) => event.match._id);

      const _matchesToDisplay = props.matches.filter((match) =>
        _matchIdsInEvents.includes(match._id),
      );
      _videoPlayerContext.openVideoPlayer(
        _events,
        _matchesToDisplay,
        props.automation.calculation === 'ratio' ||
          props.automation.calculation === 'rule',
        props.automationType === 'tracker',
        {
          action: props.automation.action,
          details: props.automation.details || props.automation.success_details,
        },
        undefined,
        props.automation.action === 'sequence',
      );
    } catch (error) {
      _snackbarContext.enqueueSnackbar(t('video-player.error-body'), {
        variant: 'error',
      });
    } finally {
      _setIsLoadingVideos(false);
    }
  }

  /*
   * Render helpers
   */
  function _renderComparedScore(
    score: FIFormattedAutomationPlayerScore | null,
  ): JSX.Element | null {
    if (!score) return null;

    const _ScoreText = (
      <Typography sx={{fontSize: 'inherit'}} display="inline-flex">
        {score.formattedValue}
      </Typography>
    );
    if (typeof score.diff === 'undefined') {
      return _ScoreText;
    }

    return (
      <Stack direction="row" display="inline-flex" gap={0.5}>
        {_ScoreText}

        <Tooltip title={score.diffTooltip}>
          <Typography
            sx={{
              fontSize: 'inherit',
              color: score.diffColor,
              fontWeight: 600,
            }}>
            {score.formattedDiff}
          </Typography>
        </Tooltip>
      </Stack>
    );
  }

  function _renderNotAvailableLabel(text?: string): JSX.Element {
    return (
      <Typography
        sx={{fontSize: 'inherit', color: 'text.disabled', opacity: 0.4}}>
        {text || t('eventAutomations.values.notAvailable')}
      </Typography>
    );
  }

  /*
   * Render
   */
  if (!_automationData) return null;

  const _linkLabel = t('eventAutomations.viewCta', {
    subject: t(
      `eventAutomations.${
        props.automationType === 'tracker' ? 'trackers' : 'opponentAutomations'
      }Short`,
      {
        count: 1,
      },
    ),
  });

  return (
    <TableRow>
      {props.cells.map((cell) => {
        let _CellContent = null;

        switch (cell.key) {
          case 'title':
            _CellContent = props.automation.title;
            if (!_CellContent) {
              _CellContent = (
                <InlineFiltersSentence
                  automationType={props.automationType}
                  size="xsmall"
                  data={_automationData}
                  readonly
                />
              );
            }
            break;

          case 'tags':
            if (_tags?.length) {
              const _tagsToShow = _tags
                .slice(0, TAGS_TO_SHOW)
                .map((tag) => tag.label)
                .join(' ');
              const _hiddenTagsCount = _tags.length - TAGS_TO_SHOW;
              const _hiddenTagsLabel = [..._tags]
                .splice(0, TAGS_TO_SHOW)
                .map((tag) => tag.label)
                .join(' ');
              _CellContent = (
                <Stack direction="row" gap={0.5} display="inline-flex">
                  <Typography
                    sx={{fontSize: 'inherit', color: 'text.secondary'}}>
                    {_tagsToShow}
                  </Typography>
                  {_hiddenTagsCount > 0 && (
                    <Tooltip title={_hiddenTagsLabel}>
                      <Typography
                        sx={{fontSize: 'inherit', color: 'text.disabled'}}>
                        {`+${_hiddenTagsCount}`}
                      </Typography>
                    </Tooltip>
                  )}
                </Stack>
              );
            } else {
              _CellContent = _renderNotAvailableLabel(
                t('eventAutomations.values.noTags'),
              );
            }
            break;
          case 'score':
            if (typeof _benchmarkedScore !== 'undefined') {
              const _scoreColor = getColorForScore(_benchmarkedScore);

              _CellContent = (
                <Typography
                  sx={{
                    fontSize: 'inherit',
                    color: _scoreColor,
                    fontWeight: 600,
                  }}>
                  {roundAndFormatNumber(_benchmarkedScore)}
                </Typography>
              );
            } else {
              _CellContent = _renderNotAvailableLabel();
            }

            break;

          case 'average':
            if (!_scores) {
              _CellContent = _renderNotAvailableLabel();
              return;
            }
            _CellContent = _renderComparedScore(_scores.average);
            break;

          case 'benchmark_average':
            if (
              _scores &&
              !isNullOrUndefined(_scores?.benchmark_average?.value)
            ) {
              _CellContent = _renderComparedScore(_scores.benchmark_average);
            } else {
              _CellContent = _renderNotAvailableLabel();
            }
            break;

          case 'last_match':
            if (_scores && !isNullOrUndefined(_scores.last?.value)) {
              _CellContent = _renderComparedScore(_scores.last);
            } else {
              _CellContent = _renderNotAvailableLabel();
            }
            break;

          case 'buttons':
            _CellContent = (
              <Stack display="inline-flex" gap={3} direction="row">
                {/* Video Button */}
                <Box
                  position="relative"
                  sx={{
                    [breakpoints.down('md')]: {
                      display: 'none',
                    },
                  }}>
                  <IconButton color="primary" onClick={_onWatchVideosClick}>
                    <PlayArrowIcon />
                  </IconButton>
                  <Fade in={_isLoadingVideos}>
                    <Box
                      bgcolor="background.paper"
                      position="absolute"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      sx={{top: 0, bottom: 0, left: 0, right: 0}}>
                      <CircularProgress size={16} />
                    </Box>
                  </Fade>
                </Box>

                {/* LINK - Desktop */}
                <Button
                  variant="text"
                  size="small"
                  color="primary"
                  component={NavLink}
                  to={_automationURL}
                  sx={{
                    [breakpoints.down('md')]: {
                      display: 'none',
                    },
                  }}>
                  {_linkLabel}
                </Button>

                {/* LINK - Small screens */}
                <IconButton
                  size="small"
                  sx={{
                    [breakpoints.up('md')]: {
                      display: 'none',
                    },
                  }}
                  color="primary"
                  component={NavLink}
                  to={_automationURL}
                  title={_linkLabel}>
                  <ArrowForwardIcon />
                </IconButton>
              </Stack>
            );

            break;

          default:
            _CellContent = _renderNotAvailableLabel();
        }
        return (
          <TableCell
            key={cell.key}
            align={cell.align}
            sx={{
              [breakpoints.down('md')]: {
                display: cell.hideOnSmallScreen ? 'none' : undefined,
                width: cell.key === 'title' ? '33%' : undefined,
              },
              width: cell.key === 'title' ? '25%' : undefined,
            }}>
            {_CellContent}
          </TableCell>
        );
      })}
    </TableRow>
  );
}

export default AutomationsTableRow;
