import React from 'react';

import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import {useTranslation} from 'react-i18next';
import {lighten, useTheme} from '@mui/material';

import SearchMirrorSentence, {
  ISearchMirrorSentenceProps,
} from './search-mirror-sentence.view';

function SearchMirrorInfo(props: ISearchMirrorSentenceProps): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _theme = useTheme();
  /*
   * Side Effects
   */

  /*
   * Handlers
   */

  function _onSwitchChange() {
    if (props.data) {
      props.onChange(undefined);
    } else {
      props.onChange({
        time: {
          season_id: [2024],
        },
        player_id: '',
      });
    }
  }

  /*
   * Render
   */

  const PAPER_BG = lighten(_theme.palette.background.paper, 0.03);
  const _canMirror = Boolean(props.data);
  return (
    <Paper elevation={0} sx={{bgcolor: PAPER_BG, p: 3}}>
      <Stack gap={3}>
        {/* Checkbox */}
        <Stack>
          <FormControlLabel
            control={<Switch color="primary" size="small" />}
            label={t('scouting.mirroring.rankByPerformance')}
            checked={_canMirror}
            onChange={_onSwitchChange}
            slotProps={{
              typography: {
                variant: 'caption',
                color: 'secondary.light',
                ml: 1,
              },
            }}
          />
        </Stack>
        {/* Sentencr: Time + mirroringplayer */}
        <Box sx={{opacity: _canMirror ? 1 : 0.8}}>
          <SearchMirrorSentence {...props} />
        </Box>
        <Typography color="text.disabled" variant="body2">
          {t('scouting.mirroring.helperText')}
        </Typography>
      </Stack>
    </Paper>
  );
}

export default SearchMirrorInfo;
