import {
  getCompetitions,
  getCompetitionSeasons,
} from '@/controllers/competitions.controllers';
import {
  createCustomContext,
  createContextHook,
  createProvider,
} from '@/helpers/general/context_generators.helper';
import {
  FICompetition,
  FICompetitionSeason,
  TCompetitionType,
} from '@my-game-plan/types';
import {PropsWithChildren, useEffect, useState} from 'react';

import {useTeams} from './team.context';
import {ITypedOption} from '@/types/option.types';
import {
  competitionSeasonsToTypedOptions,
  competitionsToTypedOptions,
  sortCompetitions,
} from '@/helpers/competitions.helper';
import {useAuth} from './auth.context';

export interface ICompetitionsAPI {
  all: FICompetition[];
  getAll: () => void;
  domesticCompetition: FICompetition | null;
  activeCompetitions: FICompetition[];
  competitionDropdownOptions: ITypedOption<number>[];
  competitionSeasonDropdownOptions: ITypedOption<number>[];
  isTrackingDataSupported: boolean;
  allDomesticCompetitions: FICompetition[];
  competitionSeasons: FICompetitionSeason[];
  getCompetitionForTeam: (
    teamId: string,
    competitionType?: TCompetitionType,
    seasonId?: number,
  ) => FICompetition | null;
  displayMultiYear: boolean;
}

const context = createCustomContext<ICompetitionsAPI>();
export const useCompetitions = createContextHook(context);

function CompetitionsProvider(
  props: PropsWithChildren<React.ReactNode>,
): JSX.Element {
  /* Hooks n State */
  const [_competitions, _setCompetitions] = useState<FICompetition[]>([]);
  const [_domesticCompetition, _setDomesticCompetition] =
    useState<FICompetition | null>(null);
  const [_activecompetitions, _setActiveCompetitions] = useState<
    FICompetition[]
  >([]);
  const [_competitionDropdownOptions, _setCompetitionDropdownOptions] =
    useState<ITypedOption<number>[]>([]);
  const [
    _competitionSeasonDropdownOptions,
    _setCompetitionSeasonDropdownOptions,
  ] = useState<ITypedOption<number>[]>([]);
  const [_isTrackingDataSupported, _setIsTrackingDataSupported] =
    useState<boolean>(false);
  const [_allDomesticCompetitions, _setAllDomesticCompetitions] = useState<
    FICompetition[]
  >([]);

  const [_competitionSeasons, _setCompetitionsSeasons] = useState<
    FICompetitionSeason[]
  >([]);

  const [_displayMultiYear, _setDisplayMultiYear] = useState<boolean>(false);

  const _authContext = useAuth();
  const _teamsContext = useTeams();

  useEffect(() => {
    if (!_teamsContext.ownTeam && _authContext.user) {
      return;
    }

    const _activeCompetitions = _competitionSeasons.filter(
      (competitionSeason) =>
        _teamsContext.ownTeam &&
        competitionSeason.team_ids.includes(_teamsContext.ownTeam._id) &&
        competitionSeason.season_id === _authContext.user?.current_season_id,
    );

    // Get domestic competition
    const _domesticCompetitionSeason = _activeCompetitions.find(
      (competionSeason) =>
        competionSeason.competition_type === 'domestic_league',
    );

    if (_domesticCompetitionSeason) {
      _teamsContext.setLeagueTeams(_domesticCompetitionSeason.team_ids);
    }

    const _newDomesticCompetition = _competitions.find(
      (competition) =>
        competition._id === _domesticCompetitionSeason?.competition_id,
    );

    _setDomesticCompetition(_newDomesticCompetition ?? null);
    _setIsTrackingDataSupported(
      Boolean(_newDomesticCompetition?.tracking_provider),
    );

    _setDisplayMultiYear(Boolean(_newDomesticCompetition?.multi_year_calendar));

    // Get competitions where team is active
    const _newActiveCompetitions = _competitions.filter((competition) =>
      _activeCompetitions.some(
        (activeCompetition) =>
          activeCompetition.competition_id === competition._id,
      ),
    );
    _setActiveCompetitions(_newActiveCompetitions);

    // Set commpetition options
    _setCompetitionDropdownOptions(
      competitionsToTypedOptions(
        _competitions,
        _newDomesticCompetition?.country_code,
      ),
    );

    // Set commpetitionSeason options
    _setCompetitionSeasonDropdownOptions(
      competitionSeasonsToTypedOptions(_competitionSeasons),
    );

    // Set list of different domestic competitions
    const _newAllDomesticCompetitions = _competitions.filter(
      (competition) => competition.type === 'domestic_league',
    );

    sortCompetitions(_newAllDomesticCompetitions);

    _setAllDomesticCompetitions(_newAllDomesticCompetitions);
  }, [
    _teamsContext.ownTeam,
    _competitions,
    _competitionSeasons,
    _authContext.user,
  ]);

  /* Handlers */
  async function _getAll() {
    const _fetchedCompetitions = await getCompetitions();
    _setCompetitions(_fetchedCompetitions);

    const _fetchedCompetitionSeasons = await getCompetitionSeasons();
    _setCompetitionsSeasons(_fetchedCompetitionSeasons);
  }

  function _getCompetitionForTeam(
    teamId: string,
    competitionType?: TCompetitionType,
    seasonId?: number,
  ): FICompetition | null {
    const _competitionToCheck = competitionType || 'domestic_league';
    const _season = seasonId || _authContext.user?.current_season_id;
    const _matchingCompetitionSeason = _competitionSeasons.find((season) => {
      const _teamIdCheck = season.team_ids.some((id) => id === teamId);

      return (
        _teamIdCheck &&
        season.competition_type === _competitionToCheck &&
        season.season_id === _season
      );
    });

    if (_matchingCompetitionSeason) {
      return (
        _competitions.find(
          (competition) =>
            competition._id === _matchingCompetitionSeason.competition_id,
        ) || null
      );
    }
    return null;
  }

  /* Return context provider */
  return createProvider(context, props, {
    all: _competitions,
    getAll: _getAll,
    domesticCompetition: _domesticCompetition,
    activeCompetitions: _activecompetitions,
    isTrackingDataSupported: _isTrackingDataSupported,
    competitionDropdownOptions: _competitionDropdownOptions,
    competitionSeasonDropdownOptions: _competitionSeasonDropdownOptions,
    allDomesticCompetitions: _allDomesticCompetitions,
    competitionSeasons: _competitionSeasons,
    getCompetitionForTeam: _getCompetitionForTeam,
    displayMultiYear: _displayMultiYear,
  });
}

export default CompetitionsProvider;
