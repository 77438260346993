import React, {useEffect, useState} from 'react';
import Typography from '@mui/material/Typography';
import {useTheme} from '@mui/material';
import {roundAndFormatNumber} from '@/helpers/general/general.helper';
import {getScoreColor} from '@/helpers/player.helper';

interface IIndicatorScoreNumberProps {
  value?: number;
}

function IndicatorScoreNumber(
  props: IIndicatorScoreNumberProps,
): JSX.Element | null {
  /*
   * Hooks n State
   */
  const _theme = useTheme();
  const [_valueText, _setValueText] = useState<string>('');

  /*
   * Side effects
   */
  useEffect(() => {
    if (typeof props.value !== 'undefined') {
      const _formattedValue = roundAndFormatNumber(props.value);
      _setValueText(_formattedValue);
    }
  }, [props.value]);

  /*
   * Render
   */
  if (typeof props.value === 'undefined') {
    return null;
  }
  const _color = getScoreColor(_theme, props.value);
  return (
    <Typography color={_color} fontSize={18} lineHeight={1} fontWeight={600}>
      {_valueText}
    </Typography>
  );
}

export default IndicatorScoreNumber;
