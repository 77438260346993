import React from 'react';
import {FIPlayerWithData, TPlayerWithDataSortKey} from '@my-game-plan/types';

import Stack from '@mui/material/Stack';
import TableRow from '@mui/material/TableRow';
import TableCell, {TableCellProps} from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';
import {SxProps, Theme} from '@mui/material';

export interface IPlayersColDef extends TableCellProps {
  label: string;
  key: string;
  formatValue?: (player: FIPlayerWithData) => string | number;
  formatSecondaryValue?: (player: FIPlayerWithData) => string | number;
  renderCell?: (player: FIPlayerWithData) => JSX.Element | string;
  sortKey?: TPlayerWithDataSortKey;
}

interface IPlayersTableRowProps {
  player: FIPlayerWithData;
  colDefinitions: IPlayersColDef[];
  displayPlayerPerformance?: boolean;
}

function PlayersTableRow(props: IPlayersTableRowProps): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();

  /*
   * Side effects
   */

  /*
   * Handlers
   */

  /*
   * Render
   */

  return (
    <TableRow>
      {props.colDefinitions.map((colDef) => {
        const _cellStyle: SxProps<Theme> = {
          color: 'text.primary',
        };

        let _value: JSX.Element | string | number = '';
        if (colDef.formatValue) {
          _value = colDef.formatValue(props.player);
        } else if (colDef.renderCell) {
          _value = colDef.renderCell(props.player);
        }

        if (!_value) {
          _value = t('players.info.nA');
          _cellStyle.color = 'text.disabled';
        }

        let _secondaryValue: JSX.Element | string | number = '';
        if (colDef.formatSecondaryValue) {
          _secondaryValue = colDef.formatSecondaryValue(props.player);
        }

        return (
          <TableCell key={colDef.key} align={colDef.align} sx={_cellStyle}>
            <Stack
              component="span"
              gap={0.5}
              flexDirection="row"
              alignItems="flex-end"
              width="auto"
              display="inline-flex">
              {_value}
              {_secondaryValue && (
                <Typography
                  component="span"
                  color="text.secondary"
                  fontSize={14}>
                  {_secondaryValue}
                </Typography>
              )}
            </Stack>
          </TableCell>
        );
      })}
    </TableRow>
  );
}

export default PlayersTableRow;
