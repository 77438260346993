export type OLD_TScoutingViewType = 'search' | 'scouted-players';

export type TScoutingViewType = 'cards' | 'table';
export const SCOUTING_VIEW_TYPES: TScoutingViewType[] = ['cards', 'table'];

export type TScoutingPlayersType = 'search' | 'following';
export const SCOUTING_PLAYERS_TYPES: TScoutingPlayersType[] = [
  'search',
  'following',
];
