import React, {PropsWithChildren, useState} from 'react';

import {
  FIAutomationDataQueryParams,
  FIFormattedAutomation,
  FIPlayerWithData,
} from '@my-game-plan/types';
import {
  createContextHook,
  createCustomContext,
  createProvider,
} from '@/helpers/general/context_generators.helper';
import {useAuth} from '@/context/auth.context';

import {
  getAllAutomations,
  getAllAutomationsGroupedByPlayer,
} from '@/controllers/event-automations.controller';
import {LOADING_STATE} from '@/types/screen.types';

export interface TrackerAPI {
  // loading: boolean;
  trackers: FIFormattedAutomation[];
  getAllLoadingState: LOADING_STATE;
  getAll: (queryParams?: FIAutomationDataQueryParams) => void;

  getAllPerPlayerLoadingState: LOADING_STATE;
  getAllPerPlayer: (queryParams?: FIAutomationDataQueryParams) => void;
  players: FIPlayerWithData[];
  // hasError: boolean;

  clear: () => void;

  filters: FIAutomationDataQueryParams | null;
  setFilters: (filters: FIAutomationDataQueryParams | null) => void;
}

const context = createCustomContext<TrackerAPI>();
export const useTrackers = createContextHook(context);

export const TrackerProvider = (
  props: PropsWithChildren<React.ReactNode>,
): JSX.Element => {
  /*
   * Hooks n State
   */
  const _auth = useAuth();

  const [_getAllLoadingState, _setGetAllLoadingState] = useState<LOADING_STATE>(
    LOADING_STATE.INITING,
  );
  const [_getAllPerPlayerLoadingState, _setGetAllPerPlayerLoadingState] =
    useState<LOADING_STATE>(LOADING_STATE.INITING);

  const [_trackers, _setTrackers] = useState<FIFormattedAutomation[]>([]);
  const [_players, _setPlayers] = useState<FIPlayerWithData[]>([]);
  const [_filters, _setFilters] = useState<FIAutomationDataQueryParams | null>(
    null,
  );

  async function _getAll(queryParams?: FIAutomationDataQueryParams) {
    if (_auth.user) {
      try {
        if (_getAllLoadingState !== LOADING_STATE.INITING) {
          _setGetAllLoadingState(LOADING_STATE.LOADING);
        }
        const _fetchedList = await getAllAutomations(
          'tracker',
          _auth.user.team,
          queryParams,
        );
        _setTrackers(_fetchedList.trackers);
        _setFilters(_fetchedList.filters);
        _setGetAllLoadingState(LOADING_STATE.SUCCESS);
      } catch (error) {
        _setGetAllLoadingState(LOADING_STATE.ERROR);
      }
    }
  }

  async function _getAllPerPlayer(queryParams?: FIAutomationDataQueryParams) {
    if (_auth.user) {
      try {
        if (_getAllPerPlayerLoadingState !== LOADING_STATE.INITING) {
          _setGetAllPerPlayerLoadingState(LOADING_STATE.LOADING);
        }

        const _fetchedOverview = await getAllAutomationsGroupedByPlayer(
          'tracker',
          _auth.user.team,
          queryParams,
        );
        _setPlayers(_fetchedOverview.players_with_data);
        _setFilters(_fetchedOverview.filters);
        _setGetAllPerPlayerLoadingState(LOADING_STATE.SUCCESS);
      } catch (error) {
        _setGetAllPerPlayerLoadingState(LOADING_STATE.ERROR);
      }
    }
  }

  function _clear() {
    _setTrackers([]);
    _setGetAllLoadingState(LOADING_STATE.INITING);
    _setPlayers([]);
    _setGetAllPerPlayerLoadingState(LOADING_STATE.INITING);
  }

  return createProvider(context, props, {
    trackers: _trackers,
    getAll: _getAll,
    getAllPerPlayer: _getAllPerPlayer,
    getAllLoadingState: _getAllLoadingState,
    getAllPerPlayerLoadingState: _getAllPerPlayerLoadingState,
    players: _players,
    clear: _clear,

    filters: _filters,
    setFilters: _setFilters,
  });
};
