import React from 'react';
import {FIPlayer, FIPlayerWithData} from '@my-game-plan/types';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';

type TAvatarSize = 'small' | 'medium' | 'large';
interface IPlayerAvatarProps {
  player: FIPlayer | FIPlayerWithData;
  displayCountry?: boolean;
  size?: TAvatarSize;
}

const DEFAULT_SIZE: TAvatarSize = 'medium';
const SIZE_MAP: Record<TAvatarSize, number> = {
  small: 24,
  medium: 40,
  large: 64,
};

const BADGE_SIZE_MAP: Record<TAvatarSize, number> = {
  small: 12,
  medium: 14,
  large: 20,
};

function PlayerAvatar(props: IPlayerAvatarProps): JSX.Element {
  const _size = props.size || DEFAULT_SIZE;
  let _Badge = null;

  if (props.displayCountry && props.player.country_code) {
    _Badge = (
      <Box
        position="absolute"
        right={0}
        bottom={0}
        width={BADGE_SIZE_MAP[_size]}
        overflow="hidden"
        boxShadow={1}
        borderRadius={0.3}>
        <img
          style={{maxWidth: '100%', display: 'block'}}
          src={`${
            process.env.REACT_APP_ASSETS_URL
          }/flags/${props.player.country_code.toLowerCase()}.png`}
        />
      </Box>
    );
  }
  return (
    <Box position="relative">
      <Avatar
        sx={{
          height: SIZE_MAP[_size],
          width: SIZE_MAP[_size],
          borderColor: 'background.default',
          borderWidth: 2,
          borderStyle: 'solid',
          color: 'background.default',
          bgcolor: 'secondary.dark',
        }}
        src={props.player.image_url}
      />
      {_Badge}
    </Box>
  );
}

export default PlayerAvatar;
