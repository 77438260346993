import React, {useEffect} from 'react';

// import OverviewScreen from '@/components/overview-screen/groups/overview-groups-screen.view';
import {FIFormattedAutomation, FIPlayerWithData} from '@my-game-plan/types';
import {useTrackers} from '@/context/event-automations/tracker.context';
import {useAuth} from '@/context/auth.context';
import OverviewScreen, {
  IOverviewScreenView,
} from '@/components/overview-screen/overview-screen.view';
import {useAnalytics} from '@/context/analytics.context';
import ANALYTICS_EVENT from '@/config/analytics/event-names.config';

interface ITrackersViewProps {
  title?: string;
}

function TrackersView(props: ITrackersViewProps): JSX.Element {
  /*
   * Hooks n State
   */
  const _auth = useAuth();
  const _trackerContext = useTrackers();
  const _analyticsContext = useAnalytics();

  useEffect(() => {
    if (_auth.user) {
      _analyticsContext.trackEvent(ANALYTICS_EVENT.VIEWED_TRACKERS_OVERVIEW, {
        team_id: _auth.user.team,
      });
    }
  }, [_auth.user]);

  /*
   * Handlers
   */

  /*
   * Render
   */

  const _tableView: IOverviewScreenView<FIFormattedAutomation> = {
    loadingState: _trackerContext.getAllLoadingState,
    type: 'table',
    data: _trackerContext.trackers,
    availableFilterOptions: {
      tags: [],
      actions: [],
      players: [],
    },
    getData: _trackerContext.getAll,
  };
  const _cardView: IOverviewScreenView<FIPlayerWithData> = {
    loadingState: _trackerContext.getAllPerPlayerLoadingState,
    type: 'cards',
    data: _trackerContext.players,
    availableFilterOptions: {
      tags: [],
      actions: [],
      players: [],
    },
    getData: _trackerContext.getAllPerPlayer,
  };

  return (
    <OverviewScreen
      title={props.title}
      automationType="tracker"
      views={[_cardView, _tableView]}
      filters={_trackerContext.filters}
      onFiltersChange={_trackerContext.setFilters}
      canSelectPlayers
    />
  );
}

export default TrackersView;
