import {
  FIMatchEvent,
  FIEventAutomationSubject,
  FIEventFilters,
  TEventAutomationType,
  FIBenchmarkData,
  FIFormattedAutomation,
  IEventAutomationsUpdateAllQueryParams,
  FIEventAutomationPostData,
  FIAutomationDataQueryParams,
  FITrackersOverview,
  FITrackersOverviewList,
} from '@my-game-plan/types';

import {useAPI} from '@/hooks/api.hooks';

import {REQUEST_ERRORS} from '@/config/errors.config';
import {IAutomationDeepDiveParams} from '@/types/navigation.types';

const api = useAPI();

function getAutomationTypeEndpoint(type: TEventAutomationType): string {
  if (type === 'tracker') {
    return 'trackers';
  } else if (type === 'shadow-tracker') {
    return 'scouting/statistics';
  }
  return 'opponent-automations';
}

export async function getAllAutomations(
  type: TEventAutomationType,
  teamID: string,
  params?: FIAutomationDataQueryParams,
  optionalEventFilters?: FIEventFilters,
): Promise<FITrackersOverviewList> {
  try {
    const _params = {
      ...(params || {}),
      ...(optionalEventFilters || {}),
    };

    const _baseURL = getAutomationTypeEndpoint(type);
    const _URL = `${_baseURL}/all/${teamID}`;
    const {data} = await api.get<FITrackersOverviewList>({
      url: _URL,
      params: _params,
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (error) {
    throw new Error((error as any).message);
  }
}

export async function getAllAutomationsGroupedByPlayer(
  type: TEventAutomationType,
  teamID: string,
  params?: FIAutomationDataQueryParams,
): Promise<FITrackersOverview> {
  const _baseURL = getAutomationTypeEndpoint(type);
  const _URL = `${_baseURL}/players/${teamID}`;
  try {
    const {data} = await api.get<FITrackersOverview>({
      url: _URL,
      params: params,
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (error) {
    throw new Error((error as any).message);
  }
}

export const getSingleAutomation = async (
  type: TEventAutomationType,
  params: Readonly<Partial<IAutomationDeepDiveParams>>,
  dateAndBenchmarkFilters?: FIAutomationDataQueryParams,
  eventFilters?: FIEventFilters,
): Promise<FIFormattedAutomation> => {
  let _URL = getAutomationTypeEndpoint(type);

  if (params.player_id) {
    _URL += `/${params.player_id}`;
  }

  _URL += `/${params.id}`;

  const _eventFilters = {
    ...(dateAndBenchmarkFilters || {}),
    ...(eventFilters || {}),
  };

  try {
    const {data} = await api.get<FIFormattedAutomation>({
      url: _URL,
      params: _eventFilters,
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch {
    throw Error;
  }
};

export const fetchEventsForAutomation = async (
  type: TEventAutomationType,
  params: Readonly<Partial<IAutomationDeepDiveParams>>,
  filters?: FIEventFilters,
  dateFilters?: FIAutomationDataQueryParams,
): Promise<FIMatchEvent[]> => {
  let _URL = getAutomationTypeEndpoint(type);

  if (params.player_id) {
    _URL += `/${params.player_id}`;
  }

  _URL += `/${params.id}/events`;
  const _params = {
    ...(filters || {}),
    ...(dateFilters || {}),
  };

  try {
    const {data} = await api.get<FIMatchEvent[]>({
      url: _URL,
      params: _params,
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (error) {
    throw new Error((error as any).message);
  }
};

export const addAutomation = async (
  type: TEventAutomationType,
  opponentAutomation: Partial<FIEventAutomationPostData>,
): Promise<FIFormattedAutomation | undefined> => {
  const _URL = getAutomationTypeEndpoint(type);
  try {
    const {data} = await api.post<FIFormattedAutomation>({
      url: _URL,
      data: opponentAutomation,
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (error) {
    throw new Error('Error adding opponentAutomation');
  }
};

export const editAutomation = async (
  type: TEventAutomationType,
  id: string,
  opponentAutomation: Partial<FIEventAutomationPostData>,
): Promise<FIFormattedAutomation> => {
  const _baseURL = getAutomationTypeEndpoint(type);
  const _URL = `${_baseURL}/${id}`;
  try {
    const {data} = await api.put<FIFormattedAutomation>({
      url: _URL,
      data: opponentAutomation,
    });

    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (error) {
    throw new Error('Error adding opponentAutomation');
  }
};

export const shareAutomation = async (
  type: TEventAutomationType,
  id: string,
  data: FIEventAutomationSubject,
): Promise<FIEventAutomationSubject> => {
  const _baseURL = getAutomationTypeEndpoint(type);
  const _URL = `${_baseURL}/${id}/share`;
  try {
    const {data: res} = await api.put<FIEventAutomationSubject>({
      url: _URL,
      data: data,
    });

    if (!res) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return res;
  } catch (error) {
    throw new Error('Error when sharing opponentAutomation');
  }
};

export const deleteAutomation = async (
  type: TEventAutomationType,
  id: string,
): Promise<void> => {
  const _baseURL = getAutomationTypeEndpoint(type);
  const _URL = `${_baseURL}/${id}`;

  try {
    await api.del<void>({
      url: _URL,
    });

    return;
  } catch (error) {
    throw new Error('Error adding OpponentAutomation');
  }
};

export const benchmarkAutomation = async (
  type: TEventAutomationType,
  params: Readonly<Partial<IAutomationDeepDiveParams>>,
  benchmarkFilters: FIAutomationDataQueryParams,
  teamId?: string,
): Promise<FIBenchmarkData> => {
  let _URL = getAutomationTypeEndpoint(type);

  if (params.player_id) {
    _URL += `/${params.player_id}`;
  }

  _URL += `/${params.id}/benchmark`;
  try {
    const {data} = await api.get<FIBenchmarkData>({
      url: _URL,
      params: {...benchmarkFilters, 'team._id': teamId},
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (error) {
    throw new Error((error as any).message);
  }
};

export const updateDataForAutomations = async (
  type: TEventAutomationType,
  teamId: string,
  params: IEventAutomationsUpdateAllQueryParams,
): Promise<FIFormattedAutomation[]> => {
  try {
    const _baseURL = getAutomationTypeEndpoint(type);
    const _URL = `${_baseURL}/team/${teamId}/update-data`;
    const {data} = await api.get<FIFormattedAutomation[]>({
      url: _URL,
      params: params,
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (error) {
    throw new Error((error as any).message);
  }
};
