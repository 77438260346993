import React from 'react';
import {FIPlayerWithData} from '@my-game-plan/types';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PlayerAvatar from '../../player-avatar.view';
import Logo from '@/components/common/Logo/Logo';

interface IPlayerCellProps {
  player: FIPlayerWithData;
}
function PlayerCell(props: IPlayerCellProps): JSX.Element {
  let _Team = null;
  if (props.player.teams?.length) {
    const _team = props.player.teams[0];
    _Team = (
      <Stack direction={'row'} gap={0.5} alignItems="center">
        <Logo size="xsmall" src={_team.image_url} />
        <Typography variant="body2" color="textSecondary">
          {_team.name}
        </Typography>
      </Stack>
    );
  }
  return (
    <Stack component="span" direction="row" alignItems={'center'} gap={1}>
      <PlayerAvatar player={props.player} displayCountry />
      <Stack gap={0.5}>
        <Typography variant="body2">{props.player.name}</Typography>
        {_Team}
      </Stack>
    </Stack>
  );
}

export default PlayerCell;
