import {FIPlayerWithData} from '@my-game-plan/types';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link, useLocation} from 'react-router-dom';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';

import PlayerCardHeader from './player-card-header.view';
import PlayerCardBio from './player-card-bio.view';
import PlayerCardPerformance from './player-card-performance.view';
import {getPlayerDeepDivePath} from '@/helpers/navigation.helpers';

interface IPlayerCardProps {
  player: FIPlayerWithData;
  displayBio?: boolean;
  displayPerformance?: boolean;
  isScouting?: boolean;
  scoutingProfileId?: string;
}

function PlayerCard(props: IPlayerCardProps) {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();

  const _location = useLocation();
  const [_deepDiveLink, _setDeepDiveLink] = useState<string>('');

  /*
   * Side effects
   */
  useEffect(() => {
    const _baseURL = getPlayerDeepDivePath(
      props.player._id,
      props.isScouting,
      props.scoutingProfileId,
    );

    let _search = '';
    if (!props.isScouting) {
      _search = _location.search;
    }
    _setDeepDiveLink(`${_baseURL}${_search}`);
  }, [props.isScouting, props.player, _location, props.scoutingProfileId]);

  /*
   * Handlers
   */

  /*
   * Render
   */

  return (
    <Card sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
      <PlayerCardHeader
        player={props.player}
        isScouting={props.isScouting}
        displayPerformance={props.displayPerformance}
      />
      <CardContent sx={{flexGrow: 1}}>
        <Stack gap={3}>
          {props.displayBio && <PlayerCardBio player={props.player} />}
          {props.displayBio && props.displayPerformance && <Divider />}
          {props.displayPerformance && (
            <PlayerCardPerformance performance={props.player.performance} />
          )}
        </Stack>
      </CardContent>
      <CardActions>
        <Button component={Link} to={_deepDiveLink}>
          {t('players.cta.viewPlayer')}
        </Button>
      </CardActions>
    </Card>
  );
}

export default PlayerCard;
