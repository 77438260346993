import {REQUEST_ERRORS} from '@/config/errors.config';
import {useAPI} from '@/hooks/api.hooks';
import {TScoutingPlayersType} from '@/types/scouting.types';
import {
  FIAutomationDataQueryParams,
  FIPlayerOverview,
  FIPlayerSearchFilters,
  FIPlayerWithData,
  FIScoutingProfile,
  FIScoutingProfilePostData,
  FIScoutingProfileWithPlayers,
} from '@my-game-plan/types';
import QueryString from 'qs';

const api = useAPI();

/*
 * ADVANCED SEARCH
 */
export async function advancedSearchPlayers(
  filters: FIPlayerSearchFilters,
  searchParams?: QueryString.ParsedQs,
) {
  const _URL = 'scouting/advanced-search';

  try {
    const _response = await api.get<FIPlayerWithData[]>({
      url: _URL,
      params: {...filters, ...searchParams},
    });

    return _response;
  } catch (error) {
    throw new Error((error as any).message);
  }
}

/*
 * SCOUTING PROFILES
 */
export async function getScoutingProfiles(): Promise<
  FIScoutingProfileWithPlayers[]
> {
  const _URL = 'scouting/profiles/overview';

  try {
    const _response = await api.get<FIScoutingProfileWithPlayers[]>({
      url: _URL,
    });

    if (!_response.data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }
    return _response.data;
  } catch (error) {
    throw new Error((error as any).message);
  }
}

export async function getScoutingProfile(
  profileId: string,
): Promise<FIScoutingProfile> {
  const _URL = `scouting/profiles/${profileId}`;

  try {
    const _response = await api.get<FIScoutingProfile>({
      url: _URL,
    });

    if (!_response.data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }
    return _response.data;
  } catch (error) {
    throw new Error((error as any).message);
  }
}

export async function getPlayersForScoutingProfile(
  profileId: string,
  type: TScoutingPlayersType,
  searchParams?: QueryString.ParsedQs,
) {
  const _URL = `scouting/profiles/${profileId}/players/${type}`;

  try {
    const _response = await api.get<FIPlayerWithData[]>({
      url: _URL,
      params: searchParams,
    });

    return _response;
  } catch (error) {
    throw new Error((error as any).message);
  }
}

export async function createScoutingProfile(
  postData: FIScoutingProfilePostData,
): Promise<FIScoutingProfile> {
  const _URL = 'scouting/profiles';

  try {
    const {data} = await api.post<FIScoutingProfile>({
      url: _URL,
      data: postData,
    });

    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (error) {
    throw new Error((error as any).message);
  }
}
export async function editScoutingProfile(
  postData: FIScoutingProfilePostData,
  scoutingProfileId: string,
): Promise<FIScoutingProfile> {
  const _URL = `scouting/profiles/${scoutingProfileId}`;

  try {
    const {data} = await api.put<FIScoutingProfile>({
      url: _URL,
      data: postData,
    });

    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (error) {
    throw new Error((error as any).message);
  }
}

export async function deleteScoutingProfile(scoutingProfileId: string) {
  const _URL = `scouting/profiles/${scoutingProfileId}`;

  try {
    const _response = await api.del({
      url: _URL,
    });

    return _response;
  } catch (error) {
    throw new Error((error as any).message);
  }
}

export async function addPlayerToScoutingProfile(
  scoutingProfileId: string,
  playerId: string,
) {
  const _URL = `scouting/players/${playerId}/${scoutingProfileId}`;

  try {
    const _response = await api.post({
      url: _URL,
    });

    return _response;
  } catch (error) {
    throw new Error((error as any).message);
  }
}

export async function removePlayerFromScoutingProfile(
  scoutingProfileId: string,
  playerId: string,
) {
  const _URL = `scouting/players/${playerId}/${scoutingProfileId}`;

  try {
    const _response = await api.del({
      url: _URL,
    });

    return _response;
  } catch (error) {
    throw new Error((error as any).message);
  }
}

export async function getPlayerOverviewByScoutingProfile(
  scoutingProfileId: string,
  playerId: string,
  filters?: FIAutomationDataQueryParams,
) {
  const _URL = `scouting/profiles/${scoutingProfileId}/players/${playerId}`;

  try {
    const {data} = await api.get<FIPlayerOverview>({
      url: _URL,
      params: filters,
    });

    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (error) {
    throw new Error((error as any).message);
  }
}
