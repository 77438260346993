import React, {useEffect, useState} from 'react';

import Stack from '@mui/material/Stack';

import PlayerDetailHeader from '@/components/player-detail/header/player-detail-header.view';
import Screen from '@/components/screen/screen.view';

import {
  PlayerDetailProvider,
  usePlayerDetail,
} from '@/context/player-detail.context';

import PlayerDetailPerformance from '@/components/player-detail/player-detail-block/player-detail-performance.view';
import PlayerDetailPhysical from '@/components/player-detail/player-detail-block/player-detail-physical.view';

import {useParams} from 'react-router-dom';
import TeamDetailHeader from '@/components/player-detail/header/team-detail-header.view';

import {useAuth} from '@/context/auth.context';

import PlayerDetailObjectives from '@/components/player-detail/player-detail-block/player-detail-objectives.view';
import PlayerSpeedDial from '@/components/player-detail/player-speed-dial.view';
import {useAnalytics} from '@/context/analytics.context';
import ANALYTICS_EVENT from '@/config/analytics/event-names.config';
import HeaderFilterBar from '@/components/filter/header-filter-bar/header-filter-bar.view';
import PlayerDetailStats from '@/components/player-detail/player-detail-block/player-detail-stats.view';

function PlayerViewWithoutProvider() {
  /*
   * Hooks n State
   */
  const _authContext = useAuth();
  const _playerDetailContext = usePlayerDetail();
  const _analyticsContext = useAnalytics();
  const [_isInited, _setIsInited] = useState(false);

  /*
   * Side effects
   */

  /*
   * Handlers
   */

  useEffect(() => {
    if (_isInited) {
      return;
    }

    if (_authContext.user && _playerDetailContext.player) {
      _analyticsContext.trackEvent(ANALYTICS_EVENT.VIEWED_PLAYER_PAGE, {
        team_id: _authContext.user.team,
        player_id: _playerDetailContext.player._id,
      });
    } else if (
      _authContext.user &&
      _playerDetailContext.team &&
      _playerDetailContext.isTeamPage
    ) {
      _analyticsContext.trackEvent(ANALYTICS_EVENT.VIEWED_PLAYER_PAGE, {
        team_id: _authContext.user.team,
      });
    }

    if (!_isInited) {
      _setIsInited(true);
    }
  }, [
    _authContext.user,
    _playerDetailContext.team,
    _playerDetailContext.player,
    _playerDetailContext.isTeamPage,
    _isInited,
  ]);

  /*
   * Render
   */

  let _HeaderContent = <PlayerDetailHeader />;

  if (_playerDetailContext.isTeamPage) {
    _HeaderContent = <TeamDetailHeader />;
  }

  return (
    <Screen
      loadingState={_playerDetailContext.playerLoadingState}
      HeaderContent={_HeaderContent}
      coloredHeader
      backLink={_playerDetailContext.backLinkState || undefined}
      HeaderBottomContent={
        <HeaderFilterBar
          benchmark={{
            benchmarkOptions:
              _playerDetailContext.benchmarkAndDateFilters?.benchmark,
            onBenchmark: _playerDetailContext.onBenchmarkFilterChange,
          }}
          timeFilters={{
            filters:
              _playerDetailContext.benchmarkAndDateFilters?.own_data?.time,
            onFiltersChange: _playerDetailContext.onDateFilterChange,
          }}
          trackerInfo={{
            isObservingPlayer: !_playerDetailContext.isTeamPage,
          }}
          screen={`${
            _playerDetailContext.isTeamPage ? 'team' : 'player'
          }-screen`}
        />
      }>
      <Stack gap={8}>
        <PlayerDetailStats />
        <Stack gap={10}>
          <PlayerDetailPerformance />
          <PlayerDetailPhysical />
          {_playerDetailContext.playerSummary?.is_own_player &&
            !_playerDetailContext.isTeamPage && <PlayerDetailObjectives />}
        </Stack>
        {_playerDetailContext.playerSummary?.is_own_player && (
          <PlayerSpeedDial />
        )}
      </Stack>
    </Screen>
  );
}

function PlayerView() {
  const _params = useParams();
  return (
    <PlayerDetailProvider playerId={_params.player_id}>
      <PlayerViewWithoutProvider />
    </PlayerDetailProvider>
  );
}
export default PlayerView;
