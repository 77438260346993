import React from 'react';

import Stack from '@mui/material/Stack';

import {usePlayerDetail} from '@/context/player-detail.context';
import ScoutPlayerButton from '@/components/scouting/scout-player-button.view';

function PlayerDetailHeaderActions(): JSX.Element | null {
  /*
   * Hooks n State
   */
  const _playerDetailContext = usePlayerDetail();

  /*
   * Handlers
   */
  function _onFollowPlayer(newScoutingProfileId?: string) {
    if (newScoutingProfileId) {
      _playerDetailContext.reloadPlayerWithScoutingProfile(
        newScoutingProfileId,
      );
    }
  }

  /*
   * Render
   */

  if (
    !_playerDetailContext.player ||
    _playerDetailContext.playerSummary?.is_own_player
  ) {
    return null;
  }

  return (
    <Stack gap={2} direction="row" alignItems="center">
      <ScoutPlayerButton
        player={_playerDetailContext.player}
        layout="button"
        scouting={_playerDetailContext.playerSummary?.scouting}
        onChange={_onFollowPlayer}
        shouldSelectProfile
      />
    </Stack>
  );
}

export default PlayerDetailHeaderActions;
