import ScoutingListScreenView from '@/components/scouting/scouting-list-screen.view';
import ScoutingProfileHeaderActions from '@/components/scouting/scouting-profile-actions.view';
import {
  NAV_LEVEL_1_ROUTE,
  SCOUTING_PROFILE_ROUTES,
} from '@/config/navigation.config';
import {getScoutingProfile} from '@/controllers/scouting.controller';

import {ILinkState} from '@/types/navigation.types';
import {LOADING_STATE} from '@/types/screen.types';
import {
  FIScoutingProfile,
  SCOUTING_NO_PROFILE_OBJECT_ID,
} from '@my-game-plan/types';

import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Navigate, Route, Routes, useParams} from 'react-router-dom';

import Button from '@mui/material/Button';

import Stack from '@mui/material/Stack';

import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import CreateScoutingProfileDialog from '@/components/scouting/dialogs/create-scouting-profile-dialog.view';
import Screen from '@/components/screen/screen.view';

interface ISoutingProfileParams {
  scouting_profile_id: string;
  [key: string]: string;
}
function ScoutingProfileView() {
  /*
   * Hooks n State
   */
  const _params = useParams<ISoutingProfileParams>();

  const {t} = useTranslation();

  const [_screenTitle, _setScreenTitle] = useState<string>('');
  const [_scoutingProfile, _setScoutingProfile] =
    useState<FIScoutingProfile | null>(null);
  const [_loadingState, _setLoadingState] = useState<LOADING_STATE>(
    LOADING_STATE.INITING,
  );

  const [_isCreateDialogOpen, _setIsCreateDialogOpen] =
    useState<boolean>(false);

  /*
   * Side Effects
   */
  // Fetch scouting profile if needed
  useEffect(() => {
    _fetchProfile();
  }, [_params.scouting_profile_id]);

  /*
   * Handlers
   */

  async function _fetchProfile(): Promise<FIScoutingProfile> {
    if (!_params.scouting_profile_id) {
      throw new Error('No scouting profile id provided');
    }
    try {
      if (_loadingState !== LOADING_STATE.INITING) {
        _setLoadingState(LOADING_STATE.LOADING);
      }

      // Load scouting profile
      const _fetchedProfile = await getScoutingProfile(
        _params.scouting_profile_id,
      );
      _setScoutingProfile(_fetchedProfile);
      _setScreenTitle(_fetchedProfile.description);
      _setLoadingState(LOADING_STATE.SUCCESS);

      _setLoadingState(LOADING_STATE.SUCCESS);

      return _fetchedProfile;
    } catch (error) {
      _setLoadingState(LOADING_STATE.ERROR);
      throw new Error('Error fetching scouting profile');
    }
  }

  function _onCreateProfileClick() {
    _setIsCreateDialogOpen(true);
  }

  function _onCreateProfileDialogClose() {
    _setIsCreateDialogOpen(false);
  }

  async function _onEditSave() {
    await _fetchProfile();

    // _fetchPlayers(_newProfile.filters);
    // _navigation(
    //   `${NAV_LEVEL_1_ROUTE.SCOUTING}/profiles/${_newProfile._id}/search`,
    // );
  }

  /*
   * Render
   */

  /* Header */
  const _headerBackLink: ILinkState = {
    route: NAV_LEVEL_1_ROUTE.SCOUTING,
    label: t('scouting.title'),
  };

  let _ProfileActionsButton = null;
  if (_scoutingProfile) {
    _ProfileActionsButton = (
      <ScoutingProfileHeaderActions scoutingProfileId={_scoutingProfile._id} />
    );
  } else if (_loadingState === LOADING_STATE.SUCCESS) {
    _ProfileActionsButton = (
      <Button
        color="primary"
        variant="contained"
        // size="large"
        onClick={_onCreateProfileClick}
        startIcon={<SaveIcon />}>
        {t('scouting.profile.create.title')}
      </Button>
    );
  }
  let _HeaderRight: JSX.Element | null = (
    <Stack direction="row" spacing={1} alignItems="center">
      <Button
        color="secondary"
        variant="outlined"
        // size="large"
        onClick={_onCreateProfileClick}
        startIcon={<EditIcon />}>
        {t('scouting.profile.edit.titleShort')}
      </Button>
      {_ProfileActionsButton}
    </Stack>
  );

  if (_scoutingProfile?._id === SCOUTING_NO_PROFILE_OBJECT_ID) {
    _HeaderRight = null;
    _ProfileActionsButton = null;
  }

  return (
    <Screen
      coloredHeader
      title={_screenTitle}
      loadingState={_loadingState}
      backLink={_headerBackLink}
      HeaderRightContent={_HeaderRight}
      tabs={
        _scoutingProfile?._id === SCOUTING_NO_PROFILE_OBJECT_ID
          ? undefined
          : SCOUTING_PROFILE_ROUTES
      }>
      {_scoutingProfile && (
        <Routes>
          <Route
            path="following"
            element={
              <ScoutingListScreenView
                key="following"
                scoutingProfileId={_params.scouting_profile_id || ''}
                resultsViewType="following"
                filters={_scoutingProfile.filters}
                onSearchDialogOpen={_onCreateProfileClick}
              />
            }
          />
          <Route
            path="search"
            element={
              <ScoutingListScreenView
                key="search"
                scoutingProfileId={_params.scouting_profile_id || ''}
                resultsViewType="search"
                filters={_scoutingProfile.filters}
                onSearchDialogOpen={_onCreateProfileClick}
              />
            }
          />

          {/* Default redirect to following */}
          <Route path="*" element={<Navigate to="following" />} />
        </Routes>
      )}
      <CreateScoutingProfileDialog
        open={_isCreateDialogOpen}
        onClose={_onCreateProfileDialogClose}
        scoutingProfile={_scoutingProfile || undefined}
        onSave={_onEditSave}
      />
    </Screen>
  );
}

export default ScoutingProfileView;
