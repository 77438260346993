import {roundAndFormatNumber} from '@/helpers/general/general.helper';
import {getScoreColor} from '@/helpers/player.helper';
import {useTheme} from '@mui/material';
import {FIPerformanceOverview} from '@my-game-plan/types';
import React, {useEffect, useState} from 'react';

import Typography from '@mui/material/Typography';

interface IScoreCellProps {
  performance?: FIPerformanceOverview;
}

function ScoreCell(props: IScoreCellProps): JSX.Element | null {
  /*
   * Hooks n State
   */
  const _theme = useTheme();
  const [_valueText, _setValueText] = useState<string>('');

  /*
   * Side effects
   */
  useEffect(() => {
    if (typeof props.performance?.benchmarked_score !== 'undefined') {
      const _formattedValue = roundAndFormatNumber(
        props.performance.benchmarked_score,
      );
      _setValueText(_formattedValue);
    }
  }, [props.performance]);

  /*
   * Render
   */

  if (
    !props.performance ||
    typeof props.performance.benchmarked_score === 'undefined'
  ) {
    return null;
  }

  const _color = getScoreColor(_theme, props.performance.benchmarked_score);
  return (
    <Typography variant="body2" color={_color} fontWeight={600}>
      {_valueText}
    </Typography>
  );
}

export default ScoreCell;
