import {getScoutedPlayers} from '@/controllers/players.controller';
import {
  createContextHook,
  createCustomContext,
  createProvider,
} from '@/helpers/general/context_generators.helper';
import {
  FIPlayerSearchFilters,
  FIPlayerWithData,
  FIScoutingProfile,
  FIScoutingProfilePostData,
  FIScoutingProfileWithPlayers,
  FITrackersOverview,
} from '@my-game-plan/types';
import React, {PropsWithChildren, useEffect, useState} from 'react';
import {useAuth} from './auth.context';

import {HTTPResponse, IPagination} from '@/types/api.types';

import {
  addPlayerToScoutingProfile,
  advancedSearchPlayers,
  createScoutingProfile,
  deleteScoutingProfile,
  editScoutingProfile,
  getScoutingProfiles,
} from '@/controllers/scouting.controller';
import {LOADING_STATE} from '@/types/screen.types';
import {useConfirm} from 'material-ui-confirm';
import {useTranslation} from 'react-i18next';
import {useAnalytics} from './analytics.context';
import ANALYTICS_EVENT from '@/config/analytics/event-names.config';

type TSearchType = 'name' | 'properties';
export interface IScoutingAPI {
  profilesLoadingState: LOADING_STATE;
  profiles: FIScoutingProfileWithPlayers[];
  getScoutingProfiles: () => Promise<FIScoutingProfileWithPlayers[]>;
  searchFilters: FIPlayerSearchFilters;

  createProfile: (
    postData: FIScoutingProfilePostData,
  ) => Promise<FIScoutingProfile>;
  updateProfile: (
    postData: FIScoutingProfilePostData,
    scoutingProfileId: string,
  ) => Promise<FIScoutingProfile>;
  deleteProfile: (
    scoutingProfileId: string,
    onComplete: () => void,
  ) => Promise<void>;

  getScoutedPlayers: (
    filters?: FIPlayerSearchFilters,
  ) => Promise<HTTPResponse<FITrackersOverview>>;
  search: (
    filters: FIPlayerSearchFilters,
    type: TSearchType,
  ) => Promise<HTTPResponse<FIPlayerWithData[]>>;
}

const context = createCustomContext<IScoutingAPI>();
export const useScouting = createContextHook(context);

// export const DEFAULT_PLAYER_SEARCH_FILTERS: FIPlayerSearchFilters = {
//   page: 1,
// };

export const DEFAULT_PAGINATION: IPagination = {
  current_page: 0,
  total_pages: 0,
  total_results: 0,
  per_page: 0,
};

function ScoutingProvider(
  props: PropsWithChildren<React.ReactNode>,
): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _authContext = useAuth();
  const _confirm = useConfirm();
  const [_searchFilters, _setSearchFilters] = useState<FIPlayerSearchFilters>(
    // DEFAULT_PLAYER_SEARCH_FILTERS
    {},
  );
  const _anlayticsContext = useAnalytics();

  const [_scoutingProfilesLoadingState, _setScoutingProfilesLoadingState] =
    useState<LOADING_STATE>(LOADING_STATE.INITING);
  const [_scoutingProfiles, _setScoutingProfiles] = useState<
    FIScoutingProfileWithPlayers[]
  >([]);

  /*
   * Side Effects
   */
  useEffect(() => {
    if (!_authContext.user) {
      return;
    }

    _fetchScoutingProfiles();
  }, [_authContext.user]);

  /*
   * Handlers
   */
  async function _searchHandler(
    filters: FIPlayerSearchFilters,
    type: TSearchType,
  ): Promise<HTTPResponse<FIPlayerWithData[]>> {
    if (!_authContext.user) {
      return {
        data: [],
      };
    }

    if (type === 'properties') {
      _setSearchFilters(filters);
    }

    try {
      const _playersResponse = await advancedSearchPlayers(filters);

      return _playersResponse;
    } catch (error) {
      throw new Error((error as Error).message);
    }
  }
  async function _getScoutedPlayers(
    filters?: FIPlayerSearchFilters,
  ): Promise<HTTPResponse<FITrackersOverview>> {
    if (!_authContext.user) {
      throw new Error('No user');
    }

    try {
      const _playersResponse = await getScoutedPlayers(
        _authContext.user.team,
        filters,
      );

      return _playersResponse;
    } catch (error) {
      throw new Error((error as Error).message);
    }
  }

  async function _fetchScoutingProfiles(): Promise<
    FIScoutingProfileWithPlayers[]
  > {
    try {
      if (_scoutingProfilesLoadingState !== LOADING_STATE.INITING) {
        _setScoutingProfilesLoadingState(LOADING_STATE.LOADING);
      }

      const _fetchedProfiles = await getScoutingProfiles();
      _setScoutingProfiles(_fetchedProfiles);
      _setScoutingProfilesLoadingState(LOADING_STATE.SUCCESS);
      return _fetchedProfiles;
    } catch (error) {
      _setScoutingProfilesLoadingState(LOADING_STATE.ERROR);
      throw new Error((error as Error).message);
    }
  }

  async function _createProfile(
    postData: FIScoutingProfilePostData,
    initialPlayerIdToFollow?: string,
  ): Promise<FIScoutingProfile> {
    try {
      const _newProfile: FIScoutingProfile = await createScoutingProfile(
        postData,
      );

      _anlayticsContext.trackEvent(ANALYTICS_EVENT.CREATED_SCOUTING_PROFILE, {
        filters: postData.filters,
      });

      if (initialPlayerIdToFollow) {
        await addPlayerToScoutingProfile(
          _newProfile._id,
          initialPlayerIdToFollow,
        );
      }

      _fetchScoutingProfiles();

      return _newProfile;
    } catch (error) {
      throw new Error((error as Error).message);
    }
  }

  async function _editProfile(
    postData: FIScoutingProfilePostData,
    scoutingProfileId: string,
  ): Promise<FIScoutingProfile> {
    try {
      const _updatedProfile = await editScoutingProfile(
        postData,
        scoutingProfileId,
      );
      _fetchScoutingProfiles();

      return _updatedProfile;
    } catch (error) {
      throw new Error((error as Error).message);
    }
  }

  async function _deleteProfile(
    scoutingProfileId: string,
    onComplete: () => void,
  ): Promise<void> {
    try {
      await _confirm({
        confirmationText: t('scouting.profile.delete.cta'),
        confirmationButtonProps: {
          color: 'error',
        },
        title: t('scouting.profile.delete.title'),
        description: t('scouting.profile.delete.description'),
      });
      await deleteScoutingProfile(scoutingProfileId);
      _fetchScoutingProfiles();
      onComplete();
    } catch (error) {
      if (error) {
        throw new Error((error as Error).message);
      }
    }
  }

  /*
   * Return Context
   */
  const _contextValue: IScoutingAPI = {
    searchFilters: _searchFilters,
    getScoutedPlayers: _getScoutedPlayers,
    search: _searchHandler,

    profiles: _scoutingProfiles,
    profilesLoadingState: _scoutingProfilesLoadingState,
    getScoutingProfiles: _fetchScoutingProfiles,
    createProfile: _createProfile,
    updateProfile: _editProfile,
    deleteProfile: _deleteProfile,
  };

  return createProvider<IScoutingAPI>(context, props, _contextValue);
}

export default ScoutingProvider;
