import React, {useState} from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';

import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {darken, useTheme} from '@mui/material';
import {useTranslation} from 'react-i18next';

import {useScouting} from '@/context/scouting.context';
import {useSnackbar} from 'notistack';
import {useNavigate} from 'react-router-dom';
import {NAV_LEVEL_1_ROUTE} from '@/config/navigation.config';

interface IScoutingProfileHeaderActionsProps {
  scoutingProfileId: string;
}
function ScoutingProfileHeaderActions(
  props: IScoutingProfileHeaderActionsProps,
): JSX.Element | null {
  /*
   * Hooks n State
   */
  const _theme = useTheme();
  const {t} = useTranslation();
  const _navigate = useNavigate();
  const _snackbar = useSnackbar();
  const _scoutingContext = useScouting();
  const [_moreActionsAnchorEl, _setMoreActionsAnchorEl] =
    useState<null | HTMLElement>(null);

  /*
   * Handlers
   */
  function _onMoreActionsClick(event: React.MouseEvent<HTMLElement>) {
    _setMoreActionsAnchorEl(event.currentTarget);
  }

  function _onMoreActionsClose() {
    _setMoreActionsAnchorEl(null);
  }

  function _onDeleteConfirmed() {
    _navigate(NAV_LEVEL_1_ROUTE.SCOUTING);
  }
  async function _onDeleteProfile() {
    try {
      _scoutingContext.deleteProfile(
        props.scoutingProfileId,
        _onDeleteConfirmed,
      );
    } catch (error) {
      if (error) {
        // Display error
        _snackbar.enqueueSnackbar(t('error-states.default'), {
          variant: 'error',
        });
      }
    }
  }

  const PAPER_BG = darken(_theme.palette.background.default, 0.2);
  return (
    <>
      <IconButton onClick={_onMoreActionsClick} color="secondary" size="small">
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={_moreActionsAnchorEl}
        open={Boolean(_moreActionsAnchorEl)}
        onClose={_onMoreActionsClose}
        slotProps={{
          paper: {
            sx: {
              bgcolor: PAPER_BG,
            },
          },
        }}>
        <MenuItem onClick={_onDeleteProfile}>
          <ListItemIcon>
            <DeleteIcon color="error" />
          </ListItemIcon>
          <ListItemText
            // primaryTypographyProps={{color: 'text.secondary'}}
            primary={t('scouting.profile.delete.cta')}
          />
        </MenuItem>
      </Menu>
    </>
  );
}

export default ScoutingProfileHeaderActions;
