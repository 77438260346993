import {FIBasicPlayer, FIScoutedPlayerData} from '@my-game-plan/types';
import React, {useEffect, useState} from 'react';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import {useParams} from 'react-router-dom';
import {
  addPlayerToScoutingProfile,
  removePlayerFromScoutingProfile,
} from '@/controllers/scouting.controller';
import {useSnackbar} from 'notistack';
import {useTranslation} from 'react-i18next';
import {stopMirroringPlayer} from '@/controllers/players.controller';
import SelectProfileDialog from './dialogs/select-profile-dialog.view';
import {useScouting} from '@/context/scouting.context';

type TScoutPlayerButtonLayout = 'icon' | 'button';

interface IScoutPlayerButtonProps {
  player: FIBasicPlayer;
  scouting?: FIScoutedPlayerData;
  layout: TScoutPlayerButtonLayout;
  onChange?: (newScoutingProfile?: string) => void;
  shouldSelectProfile?: boolean;
}

function ScoutPlayerButton(props: IScoutPlayerButtonProps): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _params = useParams();
  const _scoutingContext = useScouting();
  const _snackbar = useSnackbar();
  const [_isFollowing, _setIsFollowing] = useState<boolean>(false);
  const [_isSelectProfileDialogOpen, _setIsSelectProfileDialogOpen] =
    useState<boolean>(false);

  /*
   * Side effects
   */
  useEffect(() => {
    let _isFollowingCheck =
      _params.scouting_profile_id &&
      props.scouting?.scouting_profiles?.includes(_params.scouting_profile_id);

    if (props.scouting?.mirroring_player_id) {
      _isFollowingCheck = true;
    }
    _setIsFollowing(Boolean(_isFollowingCheck));
  }, [props.scouting, _params]);

  /*
   * Handlers
   */
  async function _onClick() {
    if (_isFollowing) {
      // Unfollow
      await _stopFollowingPlayer();
    } else if (!props.shouldSelectProfile) {
      //Follow - Directly
      await _startFollowingPlayer();
    } else {
      // Follow - Select Profile
      _setIsSelectProfileDialogOpen(true);
      return;
    }
  }

  async function _startFollowingPlayer(scoutingProfileId?: string) {
    const _scoutingProfileIdToAdd =
      scoutingProfileId || _params.scouting_profile_id;
    if (!_scoutingProfileIdToAdd) {
      return;
    }

    await addPlayerToScoutingProfile(_scoutingProfileIdToAdd, props.player._id);
    _snackbar.enqueueSnackbar(
      t('scouting.followActions.startedFollowing', {
        name: props.player.name,
        interpolation: {escapeValue: false},
      }),
      {variant: 'success'},
    );
    _setIsFollowing(true);
    _scoutingContext.getScoutingProfiles();
    if (props.onChange) {
      props.onChange(_scoutingProfileIdToAdd);
    }
  }

  async function _stopFollowingPlayer() {
    if (props.scouting?.mirroring_player_id) {
      // Stop scouting mirroring player

      await stopMirroringPlayer(props.player._id);
    } else if (_params.scouting_profile_id) {
      // Remove from profile
      await removePlayerFromScoutingProfile(
        _params.scouting_profile_id,
        props.player._id,
      );
      if (props.onChange) {
        props.onChange();
      }
    }

    _snackbar.enqueueSnackbar(
      t('scouting.followActions.stoppedFollowing', {
        name: props.player.name,
        interpolation: {escapeValue: false},
      }),
    );
    _setIsFollowing(false);
  }

  function _onSelectProfileDialogClose() {
    _setIsSelectProfileDialogOpen(false);
  }

  /*
   * Render
   */
  if (props.layout === 'icon') {
    return (
      <IconButton color="primary" onClick={_onClick}>
        {_isFollowing ? <StarIcon /> : <StarOutlineIcon />}
      </IconButton>
    );
  }

  let _buttonText = t('scouting.followActions.followPlayer');
  let _StartIcon = <StarIcon />;
  if (_isFollowing) {
    _buttonText = t('scouting.followActions.stopFollowing');
    _StartIcon = <StarOutlineIcon />;
  }
  return (
    <>
      <Button
        startIcon={_StartIcon}
        color="primary"
        onClick={_onClick}
        variant={_isFollowing ? 'outlined' : 'contained'}>
        {_buttonText}
      </Button>
      {props.shouldSelectProfile && (
        <SelectProfileDialog
          isOpen={_isSelectProfileDialogOpen}
          onClose={_onSelectProfileDialogClose}
          scoutingProfileId={_params.scouting_profile_id}
          onSubmit={_startFollowingPlayer}
        />
      )}
    </>
  );
}

export default ScoutPlayerButton;
