import {INavigationRoute} from '@/types/navigation.types';

import StarIcon from '@mui/icons-material/Star';
import SearchIcon from '@mui/icons-material/Search';
import PersonIcon from '@mui/icons-material/Person';
import GroupsIcon from '@mui/icons-material/Groups';
import ShieldIcon from '@mui/icons-material/Shield';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ModeStandbyIcon from '@mui/icons-material/ModeStandby';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';

import FolderSharedIcon from '@mui/icons-material/FolderShared';
import SettingsIcon from '@mui/icons-material/Settings';
import i18n from '@/localization';

export const NAV_SIDEBAR_WIDTH_EXPANDED = 240;

export enum NAV_LEVEL_1_ROUTE {
  HOME = '/',
  TEAM = '/team',
  PLAYERS = '/players',
  OPPONENT_AUTOMATIONS = '/opponent-automations',
  SCOUTING = '/scouting',
  DASHBOARDS = '/dashboards',
  SHOTS = '/shots',
  PREFERENCES = '/preferences',
  LIBRARY = '/library',
}

export enum AUTH_ROUTES {
  LOGIN = '/login',
}

export const SIDEBAR_NAVIGATION_ROUTES: INavigationRoute[][] = [
  // MAIN: Home, team, IDP, opponent automations, scouting
  [
    {
      icon: GroupsIcon,
      label: i18n.t('navigation.team'),
      path: NAV_LEVEL_1_ROUTE.TEAM,
    },
    {
      icon: PersonIcon,
      label: i18n.t('playerPerformance.title'),
      path: NAV_LEVEL_1_ROUTE.PLAYERS,
    },
    {
      icon: ShieldIcon,
      label: i18n.t('navigation.opponent-automations'),
      path: NAV_LEVEL_1_ROUTE.OPPONENT_AUTOMATIONS,
    },
    {
      icon: PersonSearchIcon,
      label: i18n.t('navigation.scouting'),
      path: NAV_LEVEL_1_ROUTE.SCOUTING,
    },
  ],

  // SUB 2: Dashboards, Shots
  [
    {
      icon: DashboardIcon,
      label: i18n.t('navigation.dashboards'),
      path: NAV_LEVEL_1_ROUTE.DASHBOARDS,
    },
    {
      icon: ModeStandbyIcon,
      label: i18n.t('navigation.shots'),
      path: NAV_LEVEL_1_ROUTE.SHOTS,
      subroutes: [
        {
          icon: ModeStandbyIcon,
          label: i18n.t('navigation.shots_created'),
          path: `${NAV_LEVEL_1_ROUTE.SHOTS}/created`,
        },
        {
          icon: HighlightOffIcon,
          label: i18n.t('navigation.shots_conceded'),
          path: `${NAV_LEVEL_1_ROUTE.SHOTS}/conceded`,
        },
      ],
    },
  ],

  // SUB 3:  Liberary, Preferences
  [
    {
      icon: FolderSharedIcon,
      label: i18n.t('uploads.library'),
      path: NAV_LEVEL_1_ROUTE.LIBRARY,
    },
    {
      icon: SettingsIcon,
      label: i18n.t('navigation.preferences'),
      path: NAV_LEVEL_1_ROUTE.PREFERENCES,
    },
  ],
];

export const LIBRARY_ROUTES: INavigationRoute[] = [
  {
    path: '/file-uploads',
    label: i18n.t('uploads.title'),
  },
  {
    path: '/match-videos',
    label: i18n.t('uploads.matchVideos.title'),
  },
  {
    path: '/single-sends',
    label: i18n.t('singleSends.title'),
  },
];

export const PREFERENCES_ROUTES: INavigationRoute[] = [
  {
    path: 'data-display',
    label: i18n.t('preferences.dataDisplay.title'),
  },
  {
    path: 'tags',
    label: i18n.t('preferences.tags.title'),
  },
];

export const SHOT_ANALYSIS_ROUTES: INavigationRoute[] = [
  {
    path: 'created',
    label: i18n.t('navigation.shots_created'),
    icon: ModeStandbyIcon,
  },
  {
    path: 'conceded',
    label: i18n.t('navigation.shots_conceded'),
    icon: HighlightOffIcon,
  },
];

export const SCOUTING_PROFILE_ROUTES: INavigationRoute[] = [
  {
    path: 'following',
    label: i18n.t('scouting.profile.followingPlayers'),
    icon: StarIcon,
  },
  {
    path: 'search',
    label: i18n.t('scouting.searchResults'),
    icon: SearchIcon,
  },
];
