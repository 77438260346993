import {
  IPlayerPositionMap,
  POSITIONS_MAP,
} from '@/config/player-positions.config';
import {
  FIPlayer,
  FIPlayerMarketValue,
  MAX_MARKET_VALUE,
} from '@my-game-plan/types';
import {round} from 'lodash';
import {roundAndFormatNumber} from './general/general.helper';
import {Theme} from '@mui/material';

export function getRandomPlayerFromPosition(
  positionMap: IPlayerPositionMap | undefined,
  players: FIPlayer[],
): FIPlayer | null {
  if (!positionMap) {
    // throw new Error('No position map found');
    return null;
  }
  const eligiblePlayers = players.filter((player) =>
    positionMap.positions.includes(player.position[0]),
  );
  if (eligiblePlayers.length === 0) {
    // throw new Error(
    //   `No players found for the given position map: ${positionMap.line}`,
    // );
    return null;
  }
  const randomIndex = Math.floor(Math.random() * eligiblePlayers.length);
  return eligiblePlayers[randomIndex];
}

export function getPositionMap(line: string): IPlayerPositionMap | undefined {
  return POSITIONS_MAP.find((positionMap) => positionMap.line === line);
}

export function getPlayerMarketValue(
  marketValue?: FIPlayerMarketValue[],
): number {
  if (!marketValue || marketValue.length === 0) {
    return 0;
  }

  const _latestMarketValue = marketValue[marketValue.length - 1];
  return _latestMarketValue.value;
}

export function formatPlayerValue(
  value: number,
  displayFullNumber = false,
): string {
  let _formattedValue = value.toString();

  if (!displayFullNumber && value >= 1_000_000) {
    _formattedValue = round(value / 1_000_000, 2) + 'M';
  } else if (!displayFullNumber && value >= 1_000) {
    _formattedValue = round(value / 1_000, 1) + 'K';
  } else if (displayFullNumber) {
    _formattedValue = roundAndFormatNumber(value);
  }

  return `€ ${_formattedValue}`;
}

export function generatePlayerMarketValueMarks() {
  const marks = [];

  // // Add 0 mark
  // marks.push({
  //   value: 0,
  //   label: '0',
  // });

  // Add marks with 100k steps from 0 to 10M
  for (let i = 0; i <= 10_000_000; i += 50_000) {
    marks.push({
      value: i,
      label: '',
    });
  }

  // Add marks with 100k steps from 10M to 50M
  for (let i = 10_100_000; i <= MAX_MARKET_VALUE; i += 100_000) {
    marks.push({
      value: i,
      label: '',
    });
  }

  // // Add 50M mark
  // marks.push({
  //   value: MAX_MARKET_VALUE,
  //   label: formatPlayerValue(MAX_MARKET_VALUE),
  // });

  return marks;
}

export function getScoreColor(theme: Theme, value?: number) {
  let _color = theme.palette.error.main;
  if (typeof value !== 'undefined' && value > 6.66) {
    _color = theme.palette.success.main;
  } else if (typeof value !== 'undefined' && value > 3.33) {
    _color = theme.palette.warning.main;
  }

  return _color;
}
