import React from 'react';

import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import {useTranslation} from 'react-i18next';

import {ITypedOption} from '@/types/option.types';

import styles from './segmented-control.module.scss';

import {useConfirm} from 'material-ui-confirm';
import {DEFAULT_MODAL_CONFIG} from '@/config/modal.config';

interface ISegmentedControlProps<T> {
  options: ITypedOption<T>[];
  onChange: (value: T) => void;
  value?: T;
  info?: React.ReactNode;
  infoTitle?: string;
}

function SegmentedControl<T>(props: ISegmentedControlProps<T>) {
  /* Hooks n State */

  const {t} = useTranslation();
  const _confirm = useConfirm();

  /* Handlers */
  function _onChange(event: React.MouseEvent<HTMLElement>, value: string) {
    props.onChange(value as unknown as T);
  }

  async function _onInfoModalOpen() {
    try {
      await _confirm({
        ...DEFAULT_MODAL_CONFIG,
        title: props.infoTitle ? t(props.infoTitle) : '',
        content: props.info,
      });
    } catch (error) {
      // User cancelled m
    }
  }

  /* Render */
  return (
    <div className={styles.container}>
      <ToggleButtonGroup
        exclusive
        onChange={_onChange}
        value={props.value as unknown as string}>
        {props.options.map((option) => {
          return (
            <ToggleButton
              sx={{
                borderRadius: 12,
                paddingLeft: 2,
                paddingRight: 2,
                textTransform: 'none',
              }}
              size="small"
              color="primary"
              key={option.value as unknown as string}
              value={option.value as unknown as string}>
              {option.label}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
      {props.info && (
        <div className={styles.info}>
          <IconButton
            size="small"
            color="secondary"
            onClick={_onInfoModalOpen}
            title={t('general.moreInfo')}>
            <InfoIcon />
          </IconButton>
        </div>
      )}
    </div>
  );
}

export default SegmentedControl;
