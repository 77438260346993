import React from 'react';
import {FIPerformanceOverview} from '@my-game-plan/types';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {capitalize} from 'lodash';
import Indicator from '@/components/dashboard/Indicator/Indicator';

interface IPlayerCardPerformanceProps {
  performance?: FIPerformanceOverview;
}

function PlayerCardPerformance(
  props: IPlayerCardPerformanceProps,
): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();

  /*
   * Render
   */
  if (!props.performance?.automations_count) {
    return (
      <Box
        display="flex"
        width="100%"
        height="100%"
        alignItems="center"
        justifyContent="center">
        <Typography color="secondary" fontSize={14}>
          {t('players.performance.noData.short')}
        </Typography>
      </Box>
    );
  }

  return (
    <Grid container spacing={2} width="100%">
      {props.performance.categories.map((category) => {
        return (
          <Grid item xs={12} lg={6} key={category.name}>
            <Typography fontSize={14} color="text.secondary">
              {capitalize(category.name) || t('players.performance.other')}
            </Typography>
            <Indicator value={category.benchmarked_score} hideValue />
          </Grid>
        );
      })}
    </Grid>
  );
}

export default PlayerCardPerformance;
