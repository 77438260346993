import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

export interface IStatLegend {
  color: string;
  shape: 'line' | 'dot';
}
export interface IStatProps {
  label: string;
  value: string | number;
  valueColor?: string;
  valueSuffix?: string | number;
  valueSuffixColor?: string;

  diffValue?: string | number;
  diffColor?: string;

  legendInfo?: IStatLegend;

  variant?: 'default' | 'secondary';
}

function Stat(props: IStatProps): JSX.Element {
  const _variant = props.variant || 'default';
  const _defaultValueColor = 'text.primary';
  const _valueColor = props.valueColor || _defaultValueColor;
  const _valueSuffixColor = props.valueSuffixColor || 'text.secondary';
  // const _diffColor = props.diffColor || 'text.secondary';

  let _LegendInfo = null;
  if (props.legendInfo && props.legendInfo.shape === 'line') {
    _LegendInfo = (
      <Stack direction="row" alignItems="center" gap={0.2} sx={{opacity: 0.6}}>
        <Box height="2px" width={4} bgcolor={props.legendInfo.color} />
        <Box height="2px" width={4} bgcolor={props.legendInfo.color} />
      </Stack>
    );
  } else if (props.legendInfo && props.legendInfo.shape === 'dot') {
    _LegendInfo = (
      <Box
        height={6}
        width={6}
        bgcolor={props.legendInfo.color}
        borderRadius={3}
      />
    );
  }
  return (
    <Stack gap={0.5}>
      {/* LABEL + LEGEND INFO */}
      <Stack direction="row" alignItems="center" gap={1}>
        <Typography
          variant="caption"
          color="secondary"
          fontSize={_variant === 'secondary' ? 10 : undefined}>
          {props.label}
        </Typography>
        {_LegendInfo}
      </Stack>
      {/* STAT + SUFFIX */}
      <Stack direction="row" alignItems="flex-end" gap={0.5}>
        <Typography
          fontSize={_variant === 'secondary' ? 14 : 16}
          // fontWeight={_variant === 'secondary' ? 400 : 600}
          fontWeight={600}
          color={_valueColor}
          lineHeight={1}>
          {props.value}
        </Typography>
        {props.valueSuffix && (
          <Typography
            color={_valueSuffixColor}
            lineHeight={_variant === 'secondary' ? 1 : 1.1}
            fontSize={14}>
            {props.valueSuffix}
          </Typography>
        )}

        {props.diffValue && (
          <Typography
            color={props.diffColor}
            lineHeight={_variant === 'secondary' ? 1 : 1.1}
            fontSize={14}>
            {props.diffValue}
          </Typography>
        )}
      </Stack>

      {/* {Boolean(props.diffValue) && (
        <Typography color={_diffColor}>{props.diffValue}</Typography>
      )} */}
    </Stack>
  );
}

export default Stat;
