import React, {useEffect, useState} from 'react';

import {ICommonSentenceProps} from '@/types/sentence-form.types';
import SentenceLine from './line';

import Sentence from './sentence.view';
import SequenceActions from './sequence-actions/sequence-actions.view';
import {
  OPPONENT_AUTOMATION_TEAM_SELECTOR_OPTIONS,
  TRACKER_TEAM_SELECTOR_OPTIONS,
} from '@/config/event-automation-builder.config';

import Segment from './segment';
import ObservingDropdownOpponentSelector from './segment/dropdown/observing-dropdown/observing-dropdown-opponent-selector.view';
import {TEventObservingTeam} from '@my-game-plan/types';

function InlineFiltersSequenceSentence(
  props: ICommonSentenceProps,
): JSX.Element {
  /*
   * Hooks n State
   */

  const [_isOpponentAutomation, _setIsOpponentAutomation] =
    useState<boolean>(false);

  useEffect(() => {
    _setIsOpponentAutomation(props.automationType === 'opponent-automation');
  }, [props.automationType]);

  /*
   * Render
   */
  const _commonProps: ICommonSentenceProps = {
    ...props,
  };

  function _onObservingTeamChange(value: TEventObservingTeam) {
    if (props.onChange) {
      props.onChange({
        observing_players: {
          team: value,
        },
      });
    }
  }

  // function _onPositionsChange(value: PlayerPosition[]) {
  //   if (props.onChange) {
  //     props.onChange({
  //       observing_players: {
  //         ...props.data.observing_players,
  //         positions: value,
  //       },
  //     });
  //   }
  // }

  const _BaseLineComponent = SentenceLine.AutomationBaseLineSequence;

  /* OBSERVING TEAM / PLAYERS */

  const _byDropdownOptions =
    props.automationType === 'opponent-automation'
      ? OPPONENT_AUTOMATION_TEAM_SELECTOR_OPTIONS
      : TRACKER_TEAM_SELECTOR_OPTIONS;

  const _ByAgainstDropdownSegment = (
    <Sentence.Segment>
      <Segment.Dropdown
        {...props}
        onChange={_onObservingTeamChange}
        // property="observing_players"
        options={_byDropdownOptions}
        displayReadonlyValueAsRegularText
        value={props.data.observing_players?.team || 'own'}
      />
    </Sentence.Segment>
  );

  let _ObservingDropdownSegment = null;

  if (props.automationType === 'opponent-automation' && !props.hideSubject) {
    // Opponent automations
    // "EVT by POS of next opponent" - "EVT by pos against next opponent"

    let _OpponentSegment = (
      <Segment.Text
        {..._commonProps}
        translationKey={'sentenceForm.observing.nextOpponent'}
      />
    );
    if (props.canSelectOpponent) {
      _OpponentSegment = (
        <ObservingDropdownOpponentSelector {..._commonProps} />
      );
    }

    // Temporary hack to show "by" instead of default "of" for OA in shorthand
    let _OAByAgainstDropdownSegment = _ByAgainstDropdownSegment;
    if (
      props.readonly &&
      props.data.observing_players?.team !== 'opponent' &&
      !props.data.observing_players?.positions?.length
    ) {
      _OAByAgainstDropdownSegment = (
        <Sentence.Segment>
          <Segment.Text {..._commonProps} translationKey={'sentenceForm.by'} />
        </Sentence.Segment>
      );
    }
    _ObservingDropdownSegment = (
      <>
        {_OAByAgainstDropdownSegment}

        <Sentence.Segment>{_OpponentSegment}</Sentence.Segment>
      </>
    );
  } else if (props.data.observing_players?.team === 'opponent') {
    // Trackers - Opponent
    // "EVT by POS against team"
    _ObservingDropdownSegment = (
      <>
        {_ByAgainstDropdownSegment}
        <Sentence.Segment>
          <Segment.Text
            {..._commonProps}
            translationKey={'sentenceForm.observing.entireTeam'}
          />
        </Sentence.Segment>
      </>
    );
  } else if (!props.hideSubject) {
    // Trackers - Own team
    // "EVT by team" - "EVT by player"
    _ObservingDropdownSegment = (
      <>
        {_ByAgainstDropdownSegment}
        <Sentence.Segment>
          <Sentence.Segment>
            <Segment.Players
              {..._commonProps}
              property="observing_players"
              value={props.data.observing_players}
            />
          </Sentence.Segment>
        </Sentence.Segment>
      </>
    );
  }

  return (
    <Sentence.Sentence readonly={props.readonly} size={props.size}>
      <Sentence.Section>
        <Sentence.Line>
          <_BaseLineComponent {...props} />
        </Sentence.Line>
      </Sentence.Section>

      <SequenceActions {...props} />

      {!props.readonly && (
        <Sentence.Section>
          <Sentence.Line>{_ObservingDropdownSegment}</Sentence.Line>
        </Sentence.Section>
      )}
      {/* Opponent automations */}
      {_isOpponentAutomation && !props.readonly && (
        <>
          <Sentence.Section>
            <Sentence.Line>
              <SentenceLine.OpponentAutomationsMatchLimit {...props} />
            </Sentence.Line>

            <Sentence.Line>
              <SentenceLine.OpponentAutomationsShare {...props} />
            </Sentence.Line>
          </Sentence.Section>
        </>
      )}

      {!props.readonly && _isOpponentAutomation && (
        <Sentence.Section>
          <Sentence.Line>
            <SentenceLine.Notify {...props} />
          </Sentence.Line>
        </Sentence.Section>
      )}
    </Sentence.Sentence>
  );
}

export default InlineFiltersSequenceSentence;
