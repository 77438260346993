import {FIScoutingProfileWithPlayers} from '@my-game-plan/types';
import React from 'react';

import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {NAV_LEVEL_1_ROUTE} from '@/config/navigation.config';
import PlayerCard from '@/components/players/player-card/player-card.view';
import CustomEmptyState from '@/components/error-states/CustomEmptyState';

interface IScoutingProfilePreviewProps {
  scoutingProfile: FIScoutingProfileWithPlayers;
}

function ScoutingProfilePreview(
  props: IScoutingProfilePreviewProps,
): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();

  /*
   * Side effects
   */

  /*
   * Handlers
   */

  /*
   * Render
   */
  let _btnLabel = t('scouting.profile.discoverPlayers');
  if (props.scoutingProfile.players.length) {
    _btnLabel = t('scouting.profile.allPlayers');
    _btnLabel += ` (${props.scoutingProfile.total_players})`;
  }

  const _profileLink = `${NAV_LEVEL_1_ROUTE.SCOUTING}/profiles/${props.scoutingProfile._id}`;

  let _displayPerformance = Boolean(props.scoutingProfile.filters?.mirroring);
  let _Content = null;
  if (props.scoutingProfile.players.length) {
    _Content = (
      <Grid container spacing={2}>
        {props.scoutingProfile.players.map((player) => {
          if (player.scouting?.mirroring_player_id) {
            _displayPerformance = true;
          }
          return (
            <Grid item key={player._id} xs={12} sm={6} md={4}>
              <PlayerCard
                player={player}
                displayBio
                displayPerformance={_displayPerformance}
                isScouting
                scoutingProfileId={props.scoutingProfile._id}
              />
            </Grid>
          );
        })}
      </Grid>
    );
  } else {
    _Content = (
      <CustomEmptyState
        header={t('scouting.profile.noFollowingPlayers')}
        size="small"
      />
    );
  }

  return (
    <Stack gap={2}>
      {/* Header */}
      <Stack gap={1}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center">
          <Typography fontSize={18} color="text.secondary">
            {props.scoutingProfile.description}
          </Typography>
          <Button
            color="primary"
            variant="text"
            endIcon={<ArrowForwardIcon />}
            component={Link}
            to={_profileLink}>
            {_btnLabel}
          </Button>
        </Stack>
        <Divider />
      </Stack>
      {_Content}
    </Stack>
  );
}

export default ScoutingProfilePreview;
