import React from 'react';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import BoltIcon from '@mui/icons-material/Bolt';

import {FIEventFilters, FIMatchEvent} from '@my-game-plan/types';
import Logo from '../../common/Logo/Logo';
import {PlayerDetails} from '../../common/player-details/PlayerDetails';
import styles from './event-popup.module.scss';
import {
  formatDate,
  isNullOrUndefined,
  roundAndFormatNumber,
  zeroPad,
} from '@/helpers/general/general.helper';
import {get} from 'lodash';

import {translateAction} from '@/helpers/translation.helper';

interface EventPopupProps {
  event: FIMatchEvent;
  metric?: keyof FIEventFilters;
}

function EventPopup(props: EventPopupProps): JSX.Element {
  const {player, match} = props.event;

  const _withReceivingPlayer = () => {
    if (props.event.result !== 'successful') return;

    const _receivingPlayer = props.event.pass?.receiving_player;
    if (_receivingPlayer) {
      return <PlayerDetails player={_receivingPlayer} size="small" />;
    }
  };

  const _chevronRight = () => {
    if (props.event.result !== 'successful') return;
    const _receivingPlayer = props.event.pass?.receiving_player;
    if (_receivingPlayer) {
      return <KeyboardArrowRightIcon fontSize="small" color="secondary" />;
    }
  };

  let _Metric = null;
  if (props.metric) {
    let _metricValue = get(props.event, props.metric);
    if (!isNullOrUndefined(_metricValue)) {
      _metricValue = roundAndFormatNumber(_metricValue, 2);
      _Metric = (
        <div className={styles.metric}>
          <BoltIcon fontSize="small" color="primary" />
          <span>{`${_metricValue}`}</span>
        </div>
      );
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.eventType}>
        {translateAction(props.event.event_type, 1).toUpperCase()}
      </div>
      <div className={styles.players}>
        <div className={styles.firstPlayer}>
          <PlayerDetails player={player} size="small" />
        </div>
        {_chevronRight()}
        {_withReceivingPlayer()}
      </div>
      <div className={styles.data}>
        <div className={styles.gameInfo}>
          <div className={styles.scoreWrapper}>
            <Logo src={match.home_team.image_url || ''} size="xsmall" />
            <div className={styles.score}>
              {`${match.home_team_end_score}-${match.away_team_end_score}`}
            </div>
            <Logo src={match.away_team.image_url || ''} size="xsmall" />
          </div>
          <div className={styles.dateInfo}>
            <div className={styles.date}>
              {formatDate(match.date, 'DD/MM/YYYY')}
            </div>
          </div>
        </div>
        <div className={styles.stats}>
          {_Metric}
          <div className={styles.time}>
            <AccessTimeIcon fontSize="small" color="secondary" />
            {`${zeroPad(props.event.minute, 2)}'${zeroPad(
              props.event.second,
              2,
            )}"`}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EventPopup;
