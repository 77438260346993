import React, {ReactNode, useEffect, useState} from 'react';
import {
  FIDisplayPlayer,
  FIFormattedAutomation,
  FIFormattedAutomationPlayerScore,
  TEventAutomationType,
  shouldInvertScore,
} from '@my-game-plan/types';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';

import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';

import Button from '@mui/material/Button';
import {useTranslation} from 'react-i18next';
import {formattedAutomationToPostData} from '@/helpers/event-automations.helper';
import InlineFiltersSentence from '@/components/automations/sentence/builder-sentence.view';
import {Link, useLocation, useParams} from 'react-router-dom';

import {formatSingleScore} from '@/helpers/automation/automation-detail-history.helper';
import Stat from '@/components/common/stat/stat.view';
import {generateURLForAutomation} from '@/helpers/automation/automation.helper';
import {ILinkState} from '@/types/navigation.types';
import {NAV_LEVEL_1_ROUTE} from '@/config/navigation.config';

interface IOverviewSimpleCardProps {
  automation: FIFormattedAutomation;
  automationType: TEventAutomationType;
  linkState?: ILinkState;
  teamId?: string;
  additionalPlayerInfo?: FIDisplayPlayer[];
}

function OverviewSimpleCard(props: IOverviewSimpleCardProps): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _location = useLocation();
  const [_title, _setTitle] = useState<string | ReactNode | null>(null);
  const [_formattedAverage, _setFormattedAverage] =
    useState<FIFormattedAutomationPlayerScore | null>(null);
  const [_to, _setTo] = useState<string>('');
  const _params = useParams();

  useEffect(() => {
    if ('title' in props.automation) {
      _setTitle(props.automation.title);
    } else {
      const _formattedData = formattedAutomationToPostData(props.automation);

      const _TitleNode = (
        <InlineFiltersSentence
          automationType={props.automationType}
          size="small"
          data={_formattedData}
          readonly
          hideSubject
          withTarget={false} //{Boolean(typeof props.automation.target !== 'undefined')}
          additionalPlayerInfo={props.additionalPlayerInfo}
        />
      );
      _setTitle(_TitleNode);
    }

    let _suffix = undefined;
    if (
      props.automation.calculation === 'occurrences' &&
      props.automation.benchmark_filters?.calculation === 'per_90'
    ) {
      _suffix = t('eventAutomations.values.per90');
    }
    if (props.automation.benchmark_scores) {
      const _newFormattedAvarage = formatSingleScore(
        'average',
        t('eventAutomations.properties.average'),
        props.automation.benchmark_scores?.average,
        props.automation.benchmark_scores?.average -
          props.automation.benchmark_scores?.benchmark_average,
        props.automation.calculation === 'ratio' ||
          props.automation.calculation === 'rule',
        _suffix,
        shouldInvertScore(props.automation),
      );

      _setFormattedAverage(_newFormattedAvarage);
    }
  }, [props.automation, props.additionalPlayerInfo]);

  // Set link to automation detail
  useEffect(() => {
    let _newTo = generateURLForAutomation(
      props.automationType,
      props.automation.statistic_id,
      props.automation._id,
      props.additionalPlayerInfo?.[0]?._id,
      _location.pathname.includes(NAV_LEVEL_1_ROUTE.PLAYERS),
      _params.scouting_profile_id,
    );

    let _search = _location.search;

    if (props.teamId && !_search) {
      _search = `?team._id=${props.teamId}`;
    } else if (props.teamId && _search && !_search.includes('team._id')) {
      _search += `&team._id=${props.teamId}`;
    }

    _newTo += _search;

    _setTo(_newTo);
  }, [
    props.automationType,
    props.automation,
    props.teamId,
    _location,
    _params,
  ]);

  /*
   * Render
   */
  const _linkLabel = t('eventAutomations.viewCta', {
    subject: t(
      `eventAutomations.${
        props.automationType === 'opponent-automation'
          ? 'opponentAutomations'
          : 'trackers'
      }Short`,
      {
        count: 1,
      },
    ),
  });

  let _Title = null;
  if (typeof _title === 'string') {
    _Title = (
      <Typography color="text.primary" fontSize={16} fontWeight={600}>
        {_title}
      </Typography>
    );
  } else if (_title) {
    _Title = _title;
  }

  return (
    <Card>
      <CardContent sx={{minHeight: 64}}>{_Title}</CardContent>
      <CardActions
        sx={{justifyContent: 'space-between', alignItems: 'flex-end'}}>
        <Box>
          {_formattedAverage && (
            <Stat
              label={_formattedAverage.label}
              value={
                _formattedAverage.formattedValue ||
                t('eventAutomations.values.notAvailable')
              }
              valueSuffix={_formattedAverage.valueSuffix}
              diffValue={_formattedAverage.formattedDiff}
              diffColor={_formattedAverage.diffColor}
            />
          )}
        </Box>
        <Button size="small" component={Link} to={_to} state={props.linkState}>
          {_linkLabel}
        </Button>
      </CardActions>
    </Card>
  );
}

export default OverviewSimpleCard;
