import {TIcon} from '@/types/filter.types';
import React from 'react';

import Button, {ButtonProps} from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Typography from '@mui/material/Typography';
import {SxProps, Theme} from '@mui/material';
import {TSentenceTextType} from '@/types/sentence-form.types';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {useTranslation} from 'react-i18next';
import Logo from '@/components/common/Logo/Logo';

export const LABEL_STYLE: SxProps<Theme> = {
  whiteSpace: 'pre',
  textTransform: 'none',
  fontWeight: '300',
  lineHeight: 1,
  fontSize: 14,
  color: 'secondary.light',
};
const LABEL_VALUE_STYLE: SxProps<Theme> = {
  fontWeight: '600',
  color: 'secondary.light',
};

export interface IFilterButtonValueSubSegment {
  type: TSentenceTextType;
  value: string;
}

export interface IHeaderFilterButtonProps extends ButtonProps {
  buttonPrefix?: string;
  buttonValues: IFilterButtonValueSubSegment[][];
  ButtonIcon: TIcon;
  PopupContent?: JSX.Element;
  disabledText?: string;

  onPopupOpen: (event: React.MouseEvent<HTMLElement>) => void;
  // onPopupClose: () => void;
  isOpen: boolean;
}

function HeaderFilterButton(props: IHeaderFilterButtonProps): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();

  /*
   * Handlers
   */

  /*
   * Render
   */

  let _Content = null;
  if (props.disabledText) {
    _Content = (
      <Tooltip title={props.disabledText}>
        <Stack direction="row" gap={1} alignItems="center">
          <Typography
            sx={{...LABEL_STYLE, fontStyle: 'italic'}}
            color="secondary">
            {t('eventAutomations.benchmark.disabled.disabled')}
          </Typography>
          <WarningAmberIcon fontSize="small" color="warning" />
        </Stack>
      </Tooltip>
    );
  } else {
    const _ArrowIcon = props.isOpen
      ? KeyboardArrowUpIcon
      : KeyboardArrowDownIcon;
    _Content = (
      <Button
        variant="text"
        color="secondary"
        size="small"
        disabled={props.disabled || Boolean(props.disabledText)}
        startIcon={<props.ButtonIcon sx={{color: 'secondary.light'}} />}
        onClick={props.onPopupOpen}
        endIcon={
          props.disabled ? undefined : (
            <_ArrowIcon sx={{color: 'secondary.main'}} />
          )
        }>
        <Stack component="span" direction="row" gap={0.5} alignItems="center">
          {props.buttonPrefix && (
            <Typography sx={LABEL_STYLE}>{props.buttonPrefix}</Typography>
          )}
          {props.buttonValues.map((value, valueIndex) => {
            return value.map((subValue, subValueIndex) => {
              // Logo
              if (subValue.type === 'logo') {
                return (
                  <Logo
                    size="small"
                    key={subValue.value}
                    src={subValue.value}
                  />
                );
              }
              // Text
              let _style = LABEL_STYLE;
              if (subValue.type === 'value') {
                _style = {
                  ..._style,
                  ...LABEL_VALUE_STYLE,
                } as SxProps<Theme>;
              }

              return (
                <Typography
                  key={`${valueIndex}-${subValueIndex}-${subValue.value}`}
                  sx={_style}>
                  {subValue.value}
                </Typography>
              );
            });
          })}
        </Stack>
      </Button>
    );
  }
  return (
    <>
      {_Content}

      {props.PopupContent}
    </>
  );
}

export default HeaderFilterButton;
