import React from 'react';
import {FIPlayerWithData} from '@my-game-plan/types';

import CardHeader from '@mui/material/CardHeader';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PlayerAvatar from '../player-avatar.view';
import {useTranslation} from 'react-i18next';
import Logo from '@/components/common/Logo/Logo';
import IndicatorScoreNumber from '@/components/dashboard/Indicator/indicator-score-number.view';

interface IPlayerCardHeaderProps {
  player: FIPlayerWithData;
  isScouting?: boolean;
  displayPerformance?: boolean;
}

function PlayerCardHeader(props: IPlayerCardHeaderProps): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();

  /*
   * Render
   */

  let _Subheader = null;
  if (props.isScouting && props.player.teams?.length) {
    const _team = props.player.teams[0];
    _Subheader = (
      <Stack direction={'row'} gap={0.5} alignItems="center">
        <Logo size="xsmall" src={_team.image_url} />
        <Typography variant="body2" color="textSecondary">
          {_team.name}
        </Typography>
      </Stack>
    );
  } else if (props.player.performance?.automations_count) {
    _Subheader = `${props.player.performance.automations_count} ${t(
      'eventAutomations.trackersShort',
      {
        count: props.player.performance.automations_count,
      },
    )}`;
  }

  let _Action = null;
  if (props.displayPerformance && props.player.performance) {
    _Action = (
      <IndicatorScoreNumber
        value={props.player.performance.benchmarked_score}
      />
    );
  }
  return (
    <CardHeader
      sx={{
        '& .MuiCardHeader-action': {alignSelf: 'center', mr: 0},
      }}
      avatar={
        <PlayerAvatar player={props.player} displayCountry={props.isScouting} />
      }
      title={props.player.name}
      subheader={_Subheader}
      action={_Action}
    />
  );
}

export default PlayerCardHeader;
