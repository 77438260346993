import {FIPerformanceOverview} from '@my-game-plan/types';
import React, {useEffect, useState} from 'react';

import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';

interface IPlayerTrackersCountCellProps {
  performance?: FIPerformanceOverview;
}

function PlayerTrackersCountCell(
  props: IPlayerTrackersCountCellProps,
): JSX.Element | null {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();

  const [_warnings, _setWarnings] = useState<Record<string, number> | null>(
    null,
  );

  /*
   * Side effects
   */
  useEffect(() => {
    if (!props.performance?.missing_statistics) {
      _setWarnings(null);
      return;
    }

    const _countedWarnings: Record<string, number> = {};
    const _missingStatistics = props.performance?.missing_statistics || {};
    Object.keys(props.performance?.missing_statistics || {}).forEach((key) => {
      const _value = _missingStatistics[key];
      if (_countedWarnings[_value]) {
        _countedWarnings[_value] += 1;
      } else {
        _countedWarnings[_value] = 1;
      }
    });
    _setWarnings(_countedWarnings);
  }, [props.performance?.missing_statistics]);

  /*
   * Render
   */
  if (!props.performance) {
    return null;
  }

  const _Text = (
    <Typography variant="body2" color="textSecondary">
      {props.performance.automations_count || 0}
      {_warnings && '*'}
    </Typography>
  );

  if (!_warnings) {
    return _Text;
  }

  const _warningText = (
    <div>
      {t('eventAutomations.missingData.info')}
      <ul style={{paddingLeft: 16, margin: 0, marginTop: 4}}>
        {Object.keys(_warnings).map((key) => {
          const _trackersCount = _warnings[key];
          return (
            <li key={key}>
              <b>
                {`${_trackersCount}
                ${t('eventAutomations.trackersShort', {
                  count: _trackersCount,
                })}: `}
              </b>
              {t(`eventAutomations.missingData.${key}`, {
                count: _warnings[key],
              })}
            </li>
          );
        })}
      </ul>
    </div>
  );
  return <Tooltip title={_warningText}>{_Text}</Tooltip>;
}

export default PlayerTrackersCountCell;
