import React, {useEffect, useState} from 'react';

import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import SyncIcon from '@mui/icons-material/Sync';
// import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import {usePlayerDetail} from '@/context/player-detail.context';
import {FIPlayer} from '@my-game-plan/types';
import {useTeams} from '@/context/team.context';
import {useTranslation} from 'react-i18next';
import moment from 'moment';

function PlayerMirrorInfo(): JSX.Element | null {
  /*
   * Hooks n Sate
   */
  const {t} = useTranslation();
  const _playerDetailContext = usePlayerDetail();
  const _teamsContext = useTeams();
  const [_mirrorredPlayer, _setMirrorredPlayer] = useState<FIPlayer | null>(
    null,
  );
  const [_lastSyncDate, _setLastSyncDate] = useState<string>('');

  const [_menuAnchorEl, _setMenuAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    let _newMirrorredPlayer: FIPlayer | null = null;
    let _newSyncDate = '';

    if (_playerDetailContext.playerSummary?.scouting) {
      _newMirrorredPlayer =
        _teamsContext.ownPlayers.find(
          (player) =>
            player._id ===
            _playerDetailContext.playerSummary?.scouting?.mirroring_player_id,
        ) || null;
    }

    if (_playerDetailContext.playerSummary?.scouting?.last_synced_at) {
      const _date = _playerDetailContext.playerSummary.scouting
        .last_synced_at as Date;
      _newSyncDate = moment(_date).format('LL');
      const _isToday = moment(_date).isSame(moment(), 'day');
      if (_isToday) {
        _newSyncDate = t('players.performance.mirrorring.today');
      }
    }

    _setMirrorredPlayer(_newMirrorredPlayer);
    _setLastSyncDate(_newSyncDate);
  }, [_playerDetailContext.playerSummary, _teamsContext.ownPlayers]);

  /*
   * Handlers
   */
  function _onReSync() {
    if (_mirrorredPlayer?._id) {
      _onMenuClose();
      _playerDetailContext.mirrorPlayer(_mirrorredPlayer._id);
    }
  }

  function _onMenuOpen(event: React.MouseEvent<HTMLElement>) {
    _setMenuAnchorEl(event.currentTarget);
  }

  function _onMenuClose() {
    _setMenuAnchorEl(null);
  }

  function _onChangePlayer() {
    _onMenuClose();
    _playerDetailContext.mirrorPlayer();
  }

  // function _onUnlinkPlayer() {
  //   console.log('Unlink player');

  //   _onMenuClose();
  // }
  /*
   * Render
   */

  if (
    !_playerDetailContext.playerSummary?.scouting ||
    !_mirrorredPlayer ||
    _playerDetailContext.playerSummary?.is_own_player
  ) {
    return null;
  }

  const _isMenuOpen = Boolean(_menuAnchorEl);
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {/* <Avatar
        alt={_mirrorredPlayer.name}
        src={_mirrorredPlayer.image_url}
        sx={{height: 24, width: 24, bgcolor: 'secondary.dark'}}
      /> */}
      <Stack>
        <Typography fontSize={12} fontWeight={500} color="text.secondary">
          {t('players.performance.mirrorring.mirrorringPlayer', {
            player: _mirrorredPlayer.name,
            interpolation: {
              escapeValue: false,
            },
          })}
        </Typography>
        {_playerDetailContext.playerSummary.scouting.last_synced_at && (
          <Typography fontSize={12} color="text.disabled">
            {t('players.performance.mirrorring.lastSyncAt', {
              date: _lastSyncDate,
            })}
          </Typography>
        )}
      </Stack>
      {/* <IconButton
        onClick={_onReSync}
        color="primary"
        size="small"
        title={t('players.performance.mirrorring.resync')}>
        <SyncIcon />
      </IconButton> */}
      <IconButton
        color="secondary"
        onClick={_onMenuOpen}
        size="small"
        title={t('players.performance.mirrorring.moreOptions')}>
        <SyncIcon />
      </IconButton>
      <Menu open={_isMenuOpen} anchorEl={_menuAnchorEl} onClose={_onMenuClose}>
        <MenuItem onClick={_onReSync}>
          <ListItemIcon>
            <SyncIcon color="primary" />
          </ListItemIcon>
          <ListItemText>
            {t('players.performance.mirrorring.resync')}
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={_onChangePlayer}>
          <ListItemIcon>
            <EditIcon color="secondary" />
          </ListItemIcon>
          <ListItemText>
            {t('players.performance.mirrorring.changePlayer')}
          </ListItemText>
        </MenuItem>
        {/* <MenuItem onClick={_onUnlinkPlayer}>
          <ListItemIcon>
            <DeleteIcon color="error" />
          </ListItemIcon>
          <ListItemText>
            {t('players.performance.mirrorring.unlinkPlayer')}
          </ListItemText>
        </MenuItem> */}
      </Menu>
    </Stack>
  );
}

export default PlayerMirrorInfo;
