import React, {useEffect, useState} from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import CheckIcon from '@mui/icons-material/Check';
import {useTranslation} from 'react-i18next';
import {useScouting} from '@/context/scouting.context';
import {
  FIScoutingProfile,
  SCOUTING_NO_PROFILE_OBJECT_ID,
} from '@my-game-plan/types';

interface ISelectProfileDialogProps {
  scoutingProfileId?: string;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (scoutingProfileId: string) => Promise<void>;
}

function SelectProfileDialog(props: ISelectProfileDialogProps): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _scoutingContext = useScouting();
  const [_selectedProfileId, _setSelectedProfileId] = useState<string | null>(
    props.scoutingProfileId || null,
  );
  const [_scoutingProfilesOptions, _setScoutingProfilesOptions] = useState<
    FIScoutingProfile[]
  >([]);

  /*
   * Side effects
   */
  useEffect(() => {
    _setSelectedProfileId(props.scoutingProfileId || null);
  }, [props.scoutingProfileId]);

  useEffect(() => {
    _setScoutingProfilesOptions(
      _scoutingContext.profiles.filter(
        (profile) => profile._id !== SCOUTING_NO_PROFILE_OBJECT_ID,
      ),
    );
  }, [_scoutingContext.profiles]);

  /*
   * Handlers
   */
  function _onSubmit() {
    //
    if (!_selectedProfileId) {
      return;
    }

    props.onSubmit(_selectedProfileId);
    props.onClose();
  }

  function _onProfileClick(profileId: string) {
    _setSelectedProfileId(profileId);
  }

  /*
   * Render
   */
  return (
    <Dialog open={props.isOpen} onClose={props.onClose} fullWidth maxWidth="sm">
      <DialogTitle>{t('scouting.profile.add.title')}</DialogTitle>
      <DialogContent>
        <List>
          {!_scoutingProfilesOptions.length && (
            <Typography color="text.disabled">
              {t('scouting.empty.default.header')}
            </Typography>
          )}
          {_scoutingProfilesOptions.map((profile) => {
            const _isActive = _selectedProfileId === profile._id;
            return (
              <ListItemButton
                selected={_isActive}
                divider
                key={profile._id}
                onClick={() => _onProfileClick(profile._id)}>
                <ListItemText primary={profile.description} />
                {_isActive && (
                  <ListItemIcon>
                    <CheckIcon />
                  </ListItemIcon>
                )}
              </ListItemButton>
            );
          })}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="secondary">
          {t('general.cancel')}
        </Button>
        <Button
          onClick={_onSubmit}
          color="primary"
          variant="contained"
          disabled={!_selectedProfileId}>
          {t('scouting.profile.add.cta')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SelectProfileDialog;
