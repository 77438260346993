import {FIPlayerWithData} from '@my-game-plan/types';
import React from 'react';

import Grid from '@mui/material/Grid';
import PlayerCard from './player-card.view';

interface IPlayerCardsGridProps {
  players: FIPlayerWithData[];
  isScouting?: boolean;
  scoutingProfileId?: string;
  displayPlayerPerformance?: boolean;
  displayPlayerBio?: boolean;
}

function PlayerCardsGrid(props: IPlayerCardsGridProps): JSX.Element {
  return (
    <Grid container spacing={2}>
      {props.players.map((player) => {
        return (
          <Grid key={player._id} item sm={12} md={6} lg={4}>
            <PlayerCard
              player={player}
              isScouting={props.isScouting}
              displayBio={props.displayPlayerBio}
              displayPerformance={
                props.displayPlayerPerformance ||
                Boolean(player.scouting?.mirroring_player_id)
              }
              scoutingProfileId={props.scoutingProfileId}
            />
          </Grid>
        );
      })}
    </Grid>
  );
}

export default PlayerCardsGrid;
