import React, {useEffect, useState} from 'react';

import classNames from 'classnames';

import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';

import {FIMatchEvent, formatMatchTime} from '@my-game-plan/types';
import styles from './playlistItem.module.scss';
import thumbnail from '@/assets/images/video-thumbnail/thumbnail.png';
import {
  isNullOrUndefined,
  roundAndFormatNumber,
} from '@/helpers/general/general.helper';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import {
  generateTagsForEvent,
  translateEventProperty,
} from '@/helpers/translation.helper';
import {get} from 'lodash';
import {getAvailableSourcesForMatch} from '@/helpers/video.helper';

import {PlaylistItemProps} from './PlaylistItemGeneral';

function PlaylistItemEvent({
  playlistItem,
  isActive,
  isSelected,
  playClip,
  toggleSelect,
  canSelect = false,
  isRatio,
  observingMetric,
  match,
  isShowingSequences,
  eventNumber,
}: PlaylistItemProps) {
  /* Hooks n State */

  const [_visibleMatchTags, _setVisibleMatchTags] = useState<string[]>([]);
  const [_hiddenMatchTags, _setHiddenMatchTags] = useState<string[]>([]);

  useEffect(() => {
    const _matchTags = generateTagsForEvent(playlistItem).slice(0, 3);
    const _hiddenTags = generateTagsForEvent(playlistItem).slice(3);

    _setVisibleMatchTags(_matchTags);
    _setHiddenMatchTags(_hiddenTags);
  }, [playlistItem]);

  /* Functions */
  function _onPlay() {
    if (!isActive) {
      playClip(playlistItem);
    }
  }

  function _toggleSelect(
    e: React.ChangeEvent<HTMLInputElement>,
    clips: FIMatchEvent[],
  ) {
    if (toggleSelect) {
      toggleSelect(e, clips);
    }
  }

  function renderTags(tags: string[], isRenderingHiddenTags?: boolean) {
    let _tagsString = tags.map((item) => `#${item}`).join(' ');

    if (!isRenderingHiddenTags && _hiddenMatchTags?.length) {
      _tagsString += ` +${_hiddenMatchTags.length}`;
    }
    return _tagsString;
  }

  const _availableVideoSources = getAvailableSourcesForMatch(match.video);
  const _hasVideo = Boolean(_availableVideoSources.length);

  let _SuccessFulIcon = null;
  if (isRatio) {
    _SuccessFulIcon = playlistItem.successful ? (
      <CheckCircleOutlineIcon
        sx={{fontSize: 18, marginBottom: '-3px'}}
        color={'success'}
      />
    ) : (
      <CloseIcon color={'error'} sx={{fontSize: 18, marginBottom: '-3px'}} />
    );
  }

  let _Tags: string | React.ReactNode = renderTags(_visibleMatchTags);

  if (_hiddenMatchTags?.length) {
    _Tags = (
      <Tooltip title={renderTags(_hiddenMatchTags, true)}>
        <span>{_Tags}</span>
      </Tooltip>
    );
  }

  let _MetricValue = null;
  if (observingMetric) {
    const _label = translateEventProperty(observingMetric);
    let _metricValue = get(playlistItem, observingMetric);
    if (!isNullOrUndefined(_metricValue)) {
      _metricValue = roundAndFormatNumber(_metricValue, 2);
      _MetricValue = (
        <Tooltip title={_label}>
          <span className={styles.metric}>{_metricValue}</span>
        </Tooltip>
      );
    }
  }

  return (
    <Button
      disabled={!_hasVideo}
      variant={'text'}
      onClick={_onPlay}
      sx={{px: 1, py: 2, bgcolor: isActive ? 'secondary.dark' : undefined}}
      className={classNames({
        [styles.playlistItemBase]: true,
        [styles.playlistItemFocused]: isActive,
        [styles.playlistItemNoVideo]: !_hasVideo,
      })}>
      <Stack
        spacing={1.5}
        direction="row"
        alignItems="center"
        flex={1}
        minWidth={0}>
        {canSelect && toggleSelect && (
          <Box className={styles.center}>
            <Checkbox
              checked={isSelected}
              value="select-all"
              name="select-all"
              size="small"
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => _toggleSelect(e, [playlistItem])}
            />
          </Box>
        )}
        {isShowingSequences && eventNumber ? (
          <div className={styles.actionIndex}>{eventNumber}</div>
        ) : (
          <Box className={styles.playlistItemThumbnail}>
            <Box
              sx={{borderRadius: 0.5, backgroundImage: `url(${thumbnail})`}}
              className={styles.playlistItemThumbnailImage}
            />
            <Box className={styles.playlistItemThumbnailIcon}>
              <PlayArrowIcon />
            </Box>
          </Box>
        )}

        <Box className={styles.generalInfo}>
          <Box gap={0.5}>
            {isRatio && <Grid item>{_SuccessFulIcon}</Grid>}
            <span className={styles.time}>
              {formatMatchTime(playlistItem.minute, playlistItem.second)}
            </span>

            <span className={styles.playerName}>
              {playlistItem.player?.display_name}
            </span>
          </Box>

          {_visibleMatchTags?.length > 0 && (
            <Box className={styles.tags}>{_Tags}</Box>
          )}
        </Box>
        {_MetricValue}
      </Stack>
    </Button>
  );
}

export default PlaylistItemEvent;
