import React, {useEffect, useState} from 'react';
import Stat, {IStatProps} from '@/components/common/stat/stat.view';
import {usePlayerDetail} from '@/context/player-detail.context';
import {
  roundAndFormatNumber,
  toPercentage,
} from '@/helpers/general/general.helper';
import moment from 'moment';
import {useTranslation} from 'react-i18next';

import Stack from '@mui/material/Stack';

import {formatPlayerValue, getPlayerMarketValue} from '@/helpers/player.helper';

function PlayerDetailStats(): JSX.Element | null {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _playerDetailContext = usePlayerDetail();
  const [_stats, _setStats] = useState<IStatProps[][]>([]);

  /*
   * Side effects
   */
  // Set stats on player load
  useEffect(() => {
    if (!_playerDetailContext.player) {
      _setStats([]);
      return;
    }

    const _notAvailableText = t('players.info.nA');

    const _basicStats: IStatProps[] = [
      {
        label: t('players.info.position'),
        value: _playerDetailContext.player.position[0],
      },
      {
        label: t('players.info.minutesPlayed'),
        value: roundAndFormatNumber(
          _playerDetailContext.playerSummary?.minutes_played.minutes_played ||
            0,
        ),
        valueSuffix: toPercentage(
          _playerDetailContext.playerSummary?.minutes_played
            ?.percentage_played || 0,
        ),
      },
    ];
    if (!_playerDetailContext.player.position.includes('GK')) {
      _basicStats.push({
        label: t('players.info.ga'),
        value: `${_playerDetailContext.playerSummary?.stats?.goals || 0}/${
          _playerDetailContext.playerSummary?.stats?.assists || 0
        }`,
      });
    }

    if (
      !_playerDetailContext.playerSummary?.is_own_player &&
      _playerDetailContext.player.teams?.length
    ) {
      const _team = _playerDetailContext.player.teams[0];
      _basicStats.unshift({
        label: t('team.teams', {count: 1}),
        value: _team.name,
      });
    }

    const _age = moment().diff(_playerDetailContext.player.birth_date, 'years');
    const _personalStats: IStatProps[] = [
      {
        label: t('players.info.age'),
        value: _age,
      },
      {
        label: t('players.info.height'),
        value: _playerDetailContext.player.height
          ? `${_playerDetailContext.player.height}cm`
          : _notAvailableText,
      },
      {
        label: t('players.info.weight'),
        value: _playerDetailContext.player.weight
          ? `${_playerDetailContext.player.weight}kg`
          : _notAvailableText,
      },
      ...(_playerDetailContext.player.preferred_foot
        ? [
            {
              label: t('players.info.foot'),
              value: _playerDetailContext.player.preferred_foot,
            },
          ]
        : []),
      ...(_playerDetailContext.player.country
        ? [
            {
              label: t('players.info.country'),
              value: _playerDetailContext.player.country,
            },
          ]
        : []),
    ];

    let _formattedMarketValue = '';
    if (_playerDetailContext.player.market_value) {
      const _latestMarketValue = getPlayerMarketValue(
        _playerDetailContext.player.market_value,
      );
      _formattedMarketValue = formatPlayerValue(_latestMarketValue, true);
    }
    const _marketValueStats: IStatProps[] = [
      ...(_formattedMarketValue
        ? [
            {
              label: t('players.info.market_value'),
              value: _formattedMarketValue,
            },
          ]
        : []),
      ...(_playerDetailContext.player.contract_expiry_date
        ? [
            {
              label: t('players.info.contract_expiry'),
              value: moment(
                _playerDetailContext.player.contract_expiry_date as Date,
              ).format('MMMM YYYY'),
            },
          ]
        : []),
    ];

    const _newStats: IStatProps[][] = [_basicStats, _personalStats];
    if (_marketValueStats.length) {
      _newStats.push(_marketValueStats);
    }
    // _newStats.push(_basicStats);
    _setStats(_newStats);
  }, [_playerDetailContext.player, _playerDetailContext.playerSummary]);

  /*
   * Handlers
   */

  /*
   * Render
   */
  if (!_playerDetailContext.player) {
    return null;
  }

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      {_stats.map((statGroup, groupIndex) => {
        return (
          <React.Fragment key={groupIndex}>
            <Stack gap={{sm: 1, md: 4, lg: 6}} direction="row">
              {statGroup.map((stat) => {
                return (
                  <React.Fragment key={stat.label}>
                    <Stat {...stat} />
                  </React.Fragment>
                );
              })}
            </Stack>
          </React.Fragment>
        );
      })}
    </Stack>
  );
}

export default PlayerDetailStats;
