import Segment from '@/components/automations/sentence/segment';
import Sentence from '@/components/automations/sentence/sentence.view';
import {ICommonSentenceProps} from '@/types/sentence-form.types';
import {
  FIBenchmarkOptions,
  FIEventAutomationPostData,
  FIPlayerSearchMirrorParams,
} from '@my-game-plan/types';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

export interface ISearchMirrorSentenceProps {
  onChange: (value: FIPlayerSearchMirrorParams | undefined) => void;
  data?: FIPlayerSearchMirrorParams;
}

function SearchMirrorSentence(props: ISearchMirrorSentenceProps): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const COMMON_SENTENCE_PROPS: ICommonSentenceProps<FIPlayerSearchMirrorParams> =
    {
      size: 'small',
      data: props.data || {},
    };
  const [_minutesPlayedPercent, _setMinutesPlayedPercent] = useState<
    number | undefined
  >(undefined);

  /*
   * Side Effects
   */
  useEffect(() => {
    let _percentageAsNumber = props.data?.min_percentage_played;
    if (props.data?.min_percentage_played) {
      _percentageAsNumber = Math.round(props.data.min_percentage_played * 100);
    }
    _setMinutesPlayedPercent(_percentageAsNumber);
  }, [props.data?.min_percentage_played]);

  /*
   * Handlers
   */
  function _onTimeChange(value: Partial<FIBenchmarkOptions>) {
    props.onChange({
      player_id: props.data?.player_id || '',
      time: value.time,
    });
  }

  function _onPlayersChange(value: Partial<FIEventAutomationPostData>) {
    props.onChange({
      ...(props.data || {}),
      player_id: value.observing_players?.players?.[0] || '',
    });
  }

  function _onMinutesPercentChange(value: Partial<FIPlayerSearchMirrorParams>) {
    const _percent = value.min_percentage_played
      ? value.min_percentage_played / 100
      : 0;
    props.onChange({
      ...(props.data || {}),
      player_id: props.data?.player_id || '',
      min_percentage_played: _percent,
    });
  }

  /*
   * Render
   */
  const _isEnabled = Boolean(props.data);
  const _RankByText = (
    <Sentence.Segment>
      <Segment.Text
        {...COMMON_SENTENCE_PROPS}
        text={t('scouting.mirroring.form.rankByIn')}
      />
    </Sentence.Segment>
  );

  const _TimeInput = (
    <Sentence.Segment>
      <Segment.TimeRange
        {...COMMON_SENTENCE_PROPS}
        value={props.data?.time}
        property="time"
        onChange={_onTimeChange}
        disabled={!_isEnabled}
      />
    </Sentence.Segment>
  );

  const _UsingTrackersText = (
    <Sentence.Segment>
      <Segment.Text
        {...COMMON_SENTENCE_PROPS}
        text={t('scouting.mirroring.form.usingTrackers')}
      />
    </Sentence.Segment>
  );

  const _PlayersSegment = (
    <Sentence.Segment>
      <Segment.Players
        {...COMMON_SENTENCE_PROPS}
        data={{}}
        property="observing_players"
        value={{players: props.data?.player_id ? [props.data?.player_id] : []}}
        onChange={_onPlayersChange}
        required
        disabled={!_isEnabled}
        placeholder={t('players.players', {count: 1}).toLowerCase()}
      />
    </Sentence.Segment>
  );

  // Minutes played
  const _MinutesPlayedTextSegment = (
    <Sentence.Segment>
      <Segment.Text
        {...COMMON_SENTENCE_PROPS}
        text={t('eventAutomations.benchmark.includePlayers')}
      />
    </Sentence.Segment>
  );

  const _MinutesPlayedPercentTextSegment = (
    <Sentence.Segment>
      <Segment.Text {...COMMON_SENTENCE_PROPS} text={'%'} />
    </Sentence.Segment>
  );

  const _MinutesNumberInput = (
    <Sentence.Segment>
      <Segment.NumberInput
        {...COMMON_SENTENCE_PROPS}
        value={_minutesPlayedPercent || 0}
        property="min_percentage_played"
        onChange={_onMinutesPercentChange}
        isRatio
        disabled={!_isEnabled}
      />
    </Sentence.Segment>
  );
  return (
    <Sentence.Sentence {...COMMON_SENTENCE_PROPS}>
      <Sentence.Line>
        {_RankByText}
        {_TimeInput}
        {_UsingTrackersText}
        {_PlayersSegment}
      </Sentence.Line>
      <Sentence.Line>
        {_MinutesPlayedTextSegment}
        {_MinutesNumberInput}
        {_MinutesPlayedPercentTextSegment}
      </Sentence.Line>
    </Sentence.Sentence>
  );
}

export default SearchMirrorSentence;
